<template>
  <div>
    <el-row type="flex" justify="center" align="middle">
      <el-col :xs="22" :sm="20" :md="12" :lg="10" :xl="7">
        <!-- 登录 -->
        <span style="text-align: left; color: #fff; font-size: 28px"
          >宛松ERP系统>>></span
        >
        <el-divider></el-divider>
        <div class="main" v-if="true">
          <h1>登录-login</h1>
          <el-form :model="form_data">
            <el-form-item>
              <el-input
                prefix-icon="el-icon-user"
                v-model="form_data.account"
                clearable
                placeholder="请输入账号"
              >
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-input
                prefix-icon="el-icon-lock"
                v-model="form_data.password"
                show-password
                placeholder="请输入密码"
              >
              </el-input>
            </el-form-item>
            <el-form-item>
              <v-captcha-input ref="VCaptchaInputRef"></v-captcha-input>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                @click="onSubmit"
                @keyup.enter="onSubmit"
                >登录</el-button
              >
            </el-form-item>
            <el-form-item>
              <span class="register" @click="goToRegister">注册账号</span>
            </el-form-item>
          </el-form>
        </div>
      </el-col>
    </el-row>
    <el-footer style="background: #3175bb; height: 10vh">
      <div style="text-align: center; color: #fff">
        CopyRight © {{ year }} 云南宏宛松商贸有限公司 版权所有
        <a href="http://beian.miit.gov.cn/" target="_blank" style="color: #fff"
          >备案号:</a
        >
        <a href="http://beian.miit.gov.cn/" target="_blank" style="color: #fff"
          >滇ICP备2021005017号-3</a
        >
      </div></el-footer
    >
  </div>
</template>

<script>
import * as shopFunction from "@/api/shop";
export default {
  name: "Login",
  data() {
    return {
      form_data: {
        account: "",
        password: "",
      },
      loading: null,
      year: new Date().getFullYear(),
    };
  },
  methods: {
    //注册跳转
    goToRegister() {
      this.$router.push({ path: "/register" });
    },
    //提交按钮
    onSubmit() {
      //验证码校验
      if (this.form_data.account == "") {
        this.$message({
          message: "请输入用户名!",
          type: "error",
        });
        return;
      } else if (this.form_data.password == "") {
        this.$message({
          message: "请输入密码!",
          type: "error",
        });
        return;
      } else if (this.$refs.VCaptchaInputRef.validata() == "") {
        this.$message({
          message: "请输入验证码!",
          type: "error",
        });
        return;
      } else if (!this.$refs.VCaptchaInputRef.validata()) {
        this.$message({
          message: "验证码填写错误!",
          type: "error",
        });
        return;
      }
      try {
        this.showLoading();
        shopFunction.userLogin(this.form_data).then((res) => {
          if (res.data.state == "正常") {
            //本地缓存
            let etoken = res.data.etoken;
            sessionStorage.setItem("etoken", etoken);
            this.$router.push({
              path: "/billing",
            }); //name:'index'  path:'/billing'
            this.$message.success("登录成功!");
          } else if (res.data.status == "停用") {
            this.$confirm("该账号已经" + res.data.state, "提示", {
              showCancelButton: false,
              confirmButtonText: "确定",
              showClose: false, // 禁用右上角的关闭按钮
              type: "error",
              center: true,
            });
          } else if (res.data.state == "注销") {
            this.$confirm("该账号已经" + res.data.state, "提示", {
              showCancelButton: false,
              confirmButtonText: "确定",
              showClose: false, // 禁用右上角的关闭按钮
              type: "error",
              center: true,
            });
          } else {
            if (res.data.result == "该用户不存在") {
              this.$confirm(res.data.result, "提示", {
                showCancelButton: false,
                confirmButtonText: "确定",
                showClose: false, // 禁用右上角的关闭按钮
                type: "error",
                center: true,
              });
            } else if (res.data.result.err_num < 2) {
              this.$confirm(
                "密码错误第" + (res.data.result.err_num + 1) + "次",
                "提示",
                {
                  showCancelButton: false,
                  confirmButtonText: "确定",
                  showClose: false, // 禁用右上角的关闭按钮
                  type: "error",
                  center: true,
                }
              );
            } else if ((res.data.result.err_num = 2)) {
              this.$confirm(
                "该账号被锁定 " +
                  parseInt(res.data.result.remaining_lock_time / 60) +
                  "分钟 后解锁",
                "提示",
                {
                  showCancelButton: false,
                  confirmButtonText: "确定",
                  showClose: false, // 禁用右上角的关闭按钮
                  type: "error",
                  center: true,
                }
              );
            }
          }
          this.hideLoading();
        });
      } catch (e) {
        this.$message.error("登录失败!");
      } finally {
      }
    },
    //显示加载
    showLoading() {
      this.loading = this.$loading({
        lock: true,
        text: "登录中！",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    },
    //隐藏加载
    hideLoading() {
      this.loading.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.el-row {
  min-height: 90vh;
  background: rgb(49, 117, 187);
  .el-col {
    .main {
      background: rgb(121, 187, 255);
      padding: 50px;
      color: #fff;
      border-radius: 10px;
      box-shadow: 1px 1px 3px #555555;

      .el-form {
        .el-form-item {
          .register {
            cursor: pointer;
          }
          .el-button {
            width: 100%;
          }
        }
      }
      h1 {
        text-align: center;
      }
    }

    .copy {
      text-align: center;
      color: #fff;
    }
  }
}
</style>
