<template>
  <div>
    <h3 style="color: #606266; text-align: center">编码匹配</h3>
    <el-button size="mini" type="primary" @click="beforeAddSpeedexCode"
      >新增</el-button
    >
    <el-button size="mini" type="primary" @click="getSpeedexCodeData()"
      >查询</el-button
    >
    <el-table :data="tableData" style="width: 100%">
      <!-- :data="
        tableData.filter(
          (data) =>
            !search ||
            data.inventory_name.toLowerCase().includes(search.toLowerCase()) ||
            data.factory_code.toLowerCase().includes(search.toLowerCase())
        )
      " -->
      <el-table-column label="ID" prop="id"> </el-table-column>
      <el-table-column label="速必达编码" prop="speedex_code"></el-table-column>
      <el-table-column label="商品名称" prop="inventory_name"></el-table-column>
      <el-table-column label="工厂编码" prop="factory_code"></el-table-column>
      <el-table-column align="right">
        <template slot="header" slot-scope="scope">
          <div style="position: relative; display: inline-block">
            <el-input
              v-model="search"
              size="mini"
              placeholder="输入关键字搜索"
              style="padding-right: 40px"
            />
            <el-button
              size="mini"
              type="primary"
              style="position: absolute; top: 0; right: 0"
              @click="searchCode"
              >搜索</el-button
            >
          </div>
        </template>
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
            >修改</el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-dialog title="新增" :visible.sync="dialogFormVisible" width="600px">
      <el-form :model="form">
        <el-form-item label="速必达编码" :label-width="formLabelWidth">
          <el-input v-model="form.speedex_code" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="商品名称" :label-width="formLabelWidth">
          <el-input v-model="form.inventory_name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="工厂编码" :label-width="formLabelWidth">
          <el-input v-model="form.factory_code" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="addSpeedexCode">保 存</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="修改"
      :visible.sync="dialogChangeFormVisible"
      width="600px"
    >
      <el-form :model="changeForm">
        <el-form-item label="速必达编码" :label-width="formLabelWidth">
          <el-input
            v-model="changeForm.speedex_code"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="商品名称" :label-width="formLabelWidth">
          <el-input
            v-model="changeForm.inventory_name"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="工厂编码" :label-width="formLabelWidth">
          <el-input
            v-model="changeForm.factory_code"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogChangeFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="editSpeedexCode">保 存</el-button>
      </div>
    </el-dialog>
    <!-- 分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentTotalPages"
      :page-sizes="[50, 100, 200]"
      :page-size="50"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalItem"
    >
    </el-pagination>
  </div>
</template>

<script>
import * as shopFunction from "@/api/shop";
import * as common from "@/utils/common.js";
export default {
  data() {
    return {
      tableData: [],
      search: "",
      searchStatus: 0,
      currentPage: 1,
      currentCount: 50,
      totalItem: 0,
      currentTotalPages: 0,
      form: { speedex_code: "", inventory_name: "", factory_code: "" },
      changeForm: {
        id: "",
        speedex_code: "",
        inventory_name: "",
        factory_code: "",
      },
      formLabelWidth: "100px",
      dialogFormVisible: false,
      dialogChangeFormVisible: false,
    };
  },
  created() {
    this.getSpeedexCodeData();
  },
  methods: {
    getSpeedexCodeData() {
      //获取三方编码数据
      shopFunction
        .tripartiteCoding({
          page: this.currentPage,
          page_size: this.currentCount,
        })
        .then((res) => {
          if (res.statusText === "OK") {
            this.tableData = res.data.results;
            this.totalItem = res.data.count;
            this.searchStatus = 0;
            // this.currentTotalPages =
            //   parseInt(res.data.count / this.currentCount) + 1;
          } else {
            common.showErrorMessage(this, res.data.results);
          }
        })
        .catch((error) => {
          common.showErrorMessage(this, error.response.data.result);
        });
    },

    handleSizeChange(val) {
      //控制当前每页显示多少条
      this.currentCount = val;
      this.currentPage = 1;
      // console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      //控制当前显示哪一页
      this.currentPage = val;
      //判断是否是在搜索中找页码
      if (this.searchStatus === 1) {
        this.searchCodePagination();
      } else {
        this.getSpeedexCodeData();
      }

      // console.log(`当前页: ${val}`);
    },
    beforeAddSpeedexCode() {
      //添加编码前操作
      this.dialogFormVisible = true;
      this.form.factory_code = "";
      this.form.inventory_name = "";
      this.form.speedex_code = "";
    },
    addSpeedexCode() {
      //添加速必达编码
      //添加编码前,检查该编码是否已经添加过,不能重复添加
      shopFunction
        .searchTripartiteCoding({
          search: this.form.speedex_code,
        })
        .then((res) => {
          if (res.data.results.length >= 1) {
            common.showErrorMessage(
              this,
              `${this.form.speedex_code},该编码已经存在了`
            );
            this.dialogFormVisible = false;
          } else {
            shopFunction
              .addTripartiteCoding(this.form)
              .then((res) => {
                if (res.status === 201) {
                  this.getSpeedexCodeData();
                  this.dialogFormVisible = false;
                  common.showSuccessMessage(this, "添加成功!");
                } else {
                  common.showSuccessMessage(this, res.data.results);
                }
              })
              .catch((error) => {
                common.showErrorMessage(this, error.response.data.result);
              });
          }
        });
    },
    editSpeedexCode() {
      //修改编码
      let cid = this.changeForm.id;
      delete this.changeForm.id;
      let editData = this.changeForm;
      shopFunction
        .changeTripartiteCoding(cid, editData)
        .then((res) => {
          if (res.data.id === cid) {
            this.getSpeedexCodeData();
            this.dialogChangeFormVisible = false;
            common.showSuccessMessage(this, "修改成功!");
          } else {
            common.showSuccessMessage(this, res.data.results);
          }
        })
        .catch((error) => {
          common.showErrorMessage(this, error.response.data.result);
        });
    },
    handleEdit(index, row) {
      this.dialogChangeFormVisible = true;
      this.changeForm.id = row.id;
      this.changeForm.inventory_name = row.inventory_name;
      this.changeForm.factory_code = row.factory_code;
      this.changeForm.speedex_code = row.speedex_code;
    },
    handleDelete(index, row) {
      //删除三方编码
      this.$confirm("确定删除该编码吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        center: true,
      })
        .then(() => {
          shopFunction
            .deleteTripartiteCoding(row.id)
            .then((res) => {
              if (res.status === 204) {
                this.getSpeedexCodeData();
                common.showSuccessMessage(this, "删除成功!");
              } else {
                common.showSuccessMessage(this, res.data.results);
              }
            })
            .catch((error) => {
              common.showErrorMessage(this, error.response.data.result);
            });
        })
        .catch(() => {
          common.showErrorMessage(this, "取消删除!");
        });
    },
    searchCode() {
      //搜索三方编码
      shopFunction
        .searchTripartiteCoding({
          search: this.search,
        })
        .then((res) => {
          if (res.statusText === "OK") {
            this.tableData = res.data.results;
            //修改当前总条数
            this.totalItem = res.data.count;
            this.searchStatus = 1;
          } else {
            common.showSuccessMessage(this, res.data.results);
          }
        })
        .catch((error) => {
          common.showErrorMessage(this, error.response.data.result);
        });
    },
    searchCodePagination() {
      //搜索三方编码
      shopFunction
        .searchTripartiteCoding({
          search: this.search,
          page: this.currentPage,
          page_size: this.currentCount,
        })
        .then((res) => {
          // console.log(res);
          if (res.statusText === "OK") {
            this.tableData = res.data.results;
            //修改当前总条数
            this.totalItem = res.data.count;
          } else {
            common.showSuccessMessage(this, res.data.results);
          }
        })
        .catch((error) => {
          common.showErrorMessage(this, error.response.data.result);
        });
    },
  },
};
</script>