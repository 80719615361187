<template>
  <div style="position: absolute; height: 85vh; width: 80vw">
    <!-- 搜索 -->
    <div style="width: 30%; margin-bottom: 20px; display: flex">
      <el-input
        v-model="seach_input"
        placeholder="请输入搜索条件"
        style="margin-right: 10px"
        size="small"
      ></el-input>
      <el-button
        type="success"
        suffix-icon="el-icon-search"
        plain
        size="small"
        @click="seach"
        >搜索</el-button
      >
    </div>
    <!-- 表格内容 -->
    <el-table
      :header-cell-style="{ background: '#FAFAFA' }"
      :data="account_data_list"
      border
      height="64vh"
      style="width: 100%"
    >
      <el-table-column label="用户名" width="90">
        <template slot-scope="scope">
          <span size="small">{{ scope.row.account }} </span>
        </template>
      </el-table-column>
      <el-table-column label="真实姓名" width="90">
        <template slot-scope="scope">
          <span size="small">{{ scope.row.username }} </span>
        </template>
      </el-table-column>
      <el-table-column label="手机号" width="120">
        <template slot-scope="scope">
          <span size="small">{{ scope.row.staff_phone }} </span>
        </template>
      </el-table-column>
      <el-table-column label="乐助手账号" width="120">
        <template slot-scope="scope">
          <span size="small">{{ scope.row.le_account }} </span>
        </template>
      </el-table-column>
      <el-table-column label="乐助手密码" width="100">
        <template slot-scope="scope">
          <span size="small">{{ scope.row.le_assistant_pwd }} </span>
        </template>
      </el-table-column>
      <el-table-column label="T+门店编码" width="100">
        <template slot-scope="scope">
          <span size="small">{{ scope.row.shop_code }} </span>
        </template>
      </el-table-column>
      <el-table-column label="T+门店名称" width="170">
        <template slot-scope="scope">
          <span size="small">{{ scope.row.shop_name }} </span>
        </template>
      </el-table-column>
      <el-table-column label="用户状态" width="90">
        <template slot-scope="scope">
          <el-tag size="small" v-if="scope.row.state == 1" effect="dark">
            正常
          </el-tag>
          <el-tag
            size="small"
            v-else-if="scope.row.state == 2"
            effect="dark"
            type="warning"
          >
            停用
          </el-tag>
          <el-tag
            size="small"
            v-else-if="scope.row.state == 3"
            effect="dark"
            type="info"
          >
            注销
          </el-tag>
          <el-tag size="small" v-else effect="dark" type="danger">
            锁定
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="部门" width="100">
        <template slot-scope="scope">
          <span size="small">{{ scope.row.department }} </span>
        </template>
      </el-table-column>
      <el-table-column label="岗位" width="150">
        <template slot-scope="scope">
          <span size="small">{{ scope.row.staff_name }} </span>
        </template>
      </el-table-column>
      <el-table-column label="权限分组" width="150">
        <template slot-scope="scope">
          <span size="small">{{ scope.row.permission }} </span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="240">
        <template slot-scope="scope">
          <el-button
            size="small"
            type="primary"
            plain
            @click="editAccount(scope.row.staff_phone, scope.row.id)"
            >编辑</el-button
          >
          <el-button
            size="small"
            type="success"
            plain
            @click="unlock(scope.row.id)"
            >解锁</el-button
          >
          <el-button
            size="small"
            type="warning"
            plain
            @click="resetPassword(scope.row.id)"
            >重置密码</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div
      style="
        width: 20vw;
        position: relative;
        bottom: -60px;
        left: 50%;
        justify-content: center;
        margin-left: -100px;
      "
    >
      <el-pagination
        background
        layout="prev,pager, next"
        @current-change="currentChange"
        :current-page.sync="currentPage"
        :total="count"
      >
      </el-pagination>
    </div>
    <!-- 编辑弹出对话框 -->
    <el-dialog
      title="修改用户信息"
      :visible.sync="dialogShow"
      width="50%"
      :before-close="handleClose"
    >
      <el-form label-width="120px" :inline="true" :model="form_data">
        <el-form-item label="用户名">
          <el-input
            disabled
            v-model="form_data.account"
            placeholder="请输入用户名"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="用户真实姓名">
          <el-input
            v-model="form_data.username"
            placeholder="请输入用户真实姓名"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="乐助手账号">
          <el-input
            v-model="form_data.le_account"
            placeholder="请输入乐助手账号"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="乐助手密码">
          <el-input
            v-model="form_data.le_assistant_pwd"
            placeholder="请输入乐助手密码"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="T+门店编码">
          <el-input
            v-model="form_data.shop_code"
            placeholder="请输入T+门店编码"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="T+门店名称">
          <el-input
            v-model="form_data.shop_name"
            placeholder="请输入T+门店名称"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input
            v-model="form_data.staff_phone"
            placeholder="请输入手机号"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="账号状态">
          <el-select
            v-model="form_data.state"
            placeholder="请选择账号状态"
            style="width: 300px"
          >
            <el-option
              v-for="(item, index) in state_list"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="部门名称">
          <el-select
            v-model="form_data.department"
            style="width: 300px"
            placeholder="请选择部门"
            @change="changeDepartment"
          >
            <el-option
              v-for="(item, index) in department_list"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="岗位名称">
          <el-select
            v-model="form_data.staff_name"
            style="width: 300px"
            placeholder="请选择岗位"
          >
            <el-option
              v-for="(item, index) in staff_list"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="权限分组">
          <el-select
            v-model="form_data.permission"
            style="width: 300px"
            placeholder="请选择岗位"
          >
            <el-option
              v-for="(item, index) in permission_list"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogShow = false">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as shopFunction from "@/api/shop";
import * as common from "@/utils/common.js";

export default {
  data() {
    return {
      //输入框输入的内容
      seach_input: "",
      //提交的数据
      post_data: "",
      //原来的数据
      original: "",
      //存储要编辑的用户ID
      edit_id: "",
      count: 0,
      //分页功能
      start_item: 1,
      end_item: 10,
      currentPage: 1,
      //表单数据
      form_data: {
        account: "",
        username: "",
        staff_phone: "",
        le_account: "",
        le_assistant_pwd: "",
        shop_code: "",
        shop_name: "",
        state: "",
        department: "",
        staff_name: "",
        permission: "",
      },
      department: "",
      // 部门列表
      department_list: [],
      // 岗位列表
      staff_list: [],
      //权限列表
      permission_list: [],
      //获取到的用户列表
      account_data_list: [],
      //对话框显示
      dialogShow: false,
      //状态列表
      state_list: [
        {
          value: 1,
          label: "正常",
        },
        {
          value: 2,
          label: "停用",
        },
        {
          value: 3,
          label: "注销",
        },
        {
          value: 4,
          label: "锁定",
        },
      ],
    };
  },
  created() {
    this.getAccountList();
    this.getDepartmentList();
    this.getPermissionList();
  },
  updated() {
    // this.getAccountList();
  },
  methods: {
    // 重置密码
    async resetPassword(id) {
      shopFunction
        .restPassword({ id: id })
        .then((res) => {
          this.$confirm("确定将此用户密码重置吗？", "提示", {
            showCancelButton: "取消",
            confirmButtonText: "确定",
            showClose: false, // 禁用右上角的关闭按钮
            type: "warning",
            center: true,
          })
            .then(() => {
              if (res.data.errno == "ok") {
                this.$confirm(
                  res.data.result.account +
                    " 的新密码为: " +
                    res.data.result.password,
                  "提示",
                  {
                    showCancelButton: false,
                    confirmButtonText: "确定",
                    type: "success",
                    center: true,
                  }
                );
              } else {
                this.$confirm("重置密码失败！", "提示", {
                  showCancelButton: false,
                  confirmButtonText: "确定",
                  type: "error",
                  center: true,
                });
              }
            })
            .catch(() => {});
        })
        .catch((error) => {
          common.showErrorMessage(this, error.response.data.result);
        });
      this.getAccountList(); //重新加载页面数据
    },
    // 解锁按钮点击事件
    async unlock(id) {
      shopFunction
        .unlockAccount({
          id: id,
          err_num: 0,
          lock_time: "",
          state: 1,
        })
        .then((res) => {
          this.$confirm("确定要解锁该用户吗？", "提示", {
            showCancelButton: "取消",
            confirmButtonText: "确定",
            showClose: false, // 禁用右上角的关闭按钮
            type: "warning",
            center: true,
          })
            .then(() => {
              if (res.data.errno == "ok") {
                this.$confirm("解锁成功！", "提示", {
                  showCancelButton: false,
                  confirmButtonText: "确定",
                  type: "success",
                  center: true,
                });
              } else {
                this.$confirm("解锁失败！", "提示", {
                  showCancelButton: false,
                  confirmButtonText: "确定",
                  type: "error",
                  center: true,
                });
              }
            })
            .catch(() => {});
        })
        .catch((error) => {
          common.showErrorMessage(this, error.response.data.result);
        });
      this.getAccountList(); //重新加载页面数据
    },
    // 搜索按钮
    async seach() {
      shopFunction
        .searchAccount({ search: this.seach_input })
        .then((res) => {
          this.account_data_list = res.data.results;
          this.count = res.data.count;
        })
        .catch((error) => {
          common.showErrorMessage(this, error.response.data.result);
        });
    },
    //编辑用户信息
    async editAccount(phone, id) {
      this.edit_id = id;
      let myphone = phone;
      shopFunction
        .editUserAccount({ search: myphone })
        .then((res) => {
          this.form_data = res.data.results[0];
          this.original = res.data.results[0];
          if (res.data.results[0].state == 1) {
            this.form_data.state = "正常";
          } else if (res.data.results[0].state == 2) {
            this.form_data.state = "停用";
          } else if (res.data.results[0].state == 3) {
            this.form_data.state = "注销";
          } else {
            this.form_data.state = "锁定";
          }
        })
        .catch((error) => {
          common.showErrorMessage(this, error.response.data.result);
        });
      this.dialogShow = true;
    },
    //选择部门解析岗位数据
    changeDepartment(e) {
      this.taff_list = [];
      for (var i = 0; i < this.department.length; i++) {
        //解析全局数据
        //判断用户选择谁,然后解析谁的数据
        if (this.department_list[i].value == e) {
          for (var j = 0; j < this.department[i].child_list.length; j++) {
            //构造uniapp select样式数据
            this.staff_list.push({
              label: this.department[i].child_list[j].staff_name,
              value: this.department[i].child_list[j].id,
            });
          }
        }
      }
    },
    //获取部门及岗位列表
    getDepartmentList() {
      shopFunction
        .getAllDepartmentList()
        .then((res) => {
          let department = res.data.department_list;
          this.department = department;
          //循环得到部门名称
          for (var i = 0; i < department.length; i++) {
            //这里是拆解的部门名称,给部门选择
            this.department_list.push({
              label: department[i].department_name,
              value: department[i].department_id,
            });
          }
        })
        .catch((error) => {
          common.showErrorMessage(this, error.response.data.result);
        });
    },
    //获取权限分配列表
    getPermissionList() {
      shopFunction
        .getPermissionList()
        .then((res) => {
          let permission = res.data.result;
          // 循环得到部门名称;
          for (var i = 0; i < permission.length; i++) {
            //这里是拆解的部门名称,给部门选择
            this.permission_list.push({
              label: permission[i].group_name,
              value: permission[i].id,
            });
          }
        })
        .catch((error) => {
          common.showErrorMessage(this, error.response.data.result);
        });
    },
    //关闭对话框
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    //获取账号列表
    getAccountList() {
      shopFunction
        .getAccountList({
          start_item: this.start_item,
          end_item: this.end_item,
        })
        .then((res) => {
          this.count = res.data.count - 1;
          this.account_data_list = res.data.result;
          this.count = res.data.count;
        })
        .catch((error) => {
          common.showErrorMessage(this, error.response.data.result);
        });
    },
    //分页
    currentChange() {
      this.start_item = 10 * this.currentPage - 10 + 1;
      this.end_item = 10 * this.currentPage;
      this.getAccountList();
    },
    //对话框确定按钮
    confirm() {
      this.post_data = {
        id: this.edit_id,
        account: this.form_data.account,
        username: this.form_data.username,
        staff_phone: this.form_data.staff_phone,
        le_account: this.form_data.le_account,
        le_assistant_pwd: this.form_data.le_assistant_pwd,
        shop_code: this.form_data.shop_code,
        shop_name: this.form_data.shop_name,
      };
      let list = [
        this.form_data.state,
        this.form_data.department,
        this.form_data.staff_name,
        this.form_data.permission,
      ];
      for (let i = 0; i < list.length; i++) {
        if (typeof list[0] != "string") {
          this.post_data["state"] = this.form_data.state;
        }
        if (typeof list[1] != "string") {
          this.post_data["department"] = this.form_data.department;
        }
        if (typeof list[2] != "string") {
          this.post_data["staff_name"] = this.form_data.staff_name;
        }
        if (typeof list[3] != "string") {
          this.post_data["permission"] = this.form_data.permission;
        }
      }
      shopFunction
        .confirmChangeUserInfo(this.post_data)
        .then((res) => {
          this.$confirm("确定要修改该用户的信息吗？", "提示", {
            showCancelButton: "取消",
            confirmButtonText: "确定",
            showClose: false, // 禁用右上角的关闭按钮
            type: "warning",
            center: true,
          })
            .then(() => {
              if (res.data.errno == "ok") {
                this.$confirm("修改成功", "提示", {
                  showCancelButton: false,
                  confirmButtonText: "确定",
                  type: "success",
                  center: true,
                });
                this.getAccountList(); //重新加载页面数据
                this.dialogShow = false;
              } else {
                this.$confirm("修改失败", "提示", {
                  showCancelButton: false,
                  confirmButtonText: "确定",
                  type: "error",
                  center: true,
                });
              }
            })
            .catch(() => {});
        })
        .catch((error) => {
          common.showErrorMessage(this, error.response.data.result);
        });
    },
  },
};
</script>

<style>
</style>