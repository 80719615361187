<template>
  <div>
    <h4>宛松助手订单下载</h4>
    <el-row>
      <!-- 时间选择模块 -->
      <el-col :span="8">
        <el-date-picker
          v-model="date"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          @change="changeDate"
        >
        </el-date-picker>
      </el-col>
      <!-- 下拉选择部门 -->
      <el-col :span="6">
        <el-select v-model="department_value">
          <el-option
            v-for="(item, index) in department_list"
            :key="index"
            :label="item.department_name"
            :value="item.department_id"
          >
          </el-option>
        </el-select>
      </el-col>
      <!-- 下载按钮 -->
      <el-col :span="6">
        <el-button type="success" plain @click="download">下载excel</el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import * as shopFunction from "@/api/shop";
import * as common from "@/utils/common.js";
export default {
  data() {
    return {
      //时间数据
      date: "",
      //选择框岗位数据
      department_value: "",
      //获取到的部门列表
      department_list: [],
    };
  },
  created() {
    // 获取部门列表用于渲染下拉选择内容
    this.getDepartment();
  },
  methods: {
    // 显示加载
    showLoading() {
      this.loading = this.$loading({
        lock: true,
        text: "下载中！请稍后！",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    },

    // 隐藏加载
    hideLoading() {
      this.loading.close();
    },

    // 选择时间事件
    changeDate(c) {
      let end_data = c[1].slice(5, 7);
      let start_data = c[0].slice(5, 7);
      // if (end_data - start_data > 0) {
      //   this.$confirm("最多只能选择一个月！", "提示", {
      //     showCancelButton: false,
      //     confirmButtonText: "确定",
      //     type: "warning",
      //     center: true,
      //   });
      // }
    },

    // 发起请求获取部门列表
    async getDepartment() {
      await shopFunction
        .getAllDepartmentList()
        .then((res) => {
          this.department_list = res.data.department_list;
        })
        .catch((error) => {
          common.showErrorMessage(this, error.response.data.result);
        });
    },
    // 下载按钮点击事件
    async download() {
      this.showLoading();
      await shopFunction
        .downLoadAssistantOrders({
          start_time: this.date[0],
          end_time: this.date[1],
          department_id:
            this.department_value == "" ? "" : this.department_value,
        })
        .then((res) => {
          if (this.date == "") {
            this.$confirm("请选择日期！", "提示", {
              showCancelButton: false,
              confirmButtonText: "确定",
              type: "warning",
              center: true,
            });
          } else {
            let excel_title =
              "宛松订单" + this.date[0] + "至" + this.date[1] + ".xlsx";
            const blob = new Blob([res.data], {
              type: "application/octet-stream",
            });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", excel_title);
            document.body.appendChild(link);
            link.click();
            if (res.data.errno === "fail" || res.data.errno === "err") {
              this.$confirm("下载失败！", "提示", {
                showCancelButton: false,
                confirmButtonText: "确定",
                showClose: false, // 禁用右上角的关闭按钮
                type: "warning",
                center: true,
              });
            } else {
              this.$confirm("下载成功！", "提示", {
                showCancelButton: false,
                confirmButtonText: "确定",
                type: "success",
                center: true,
              });
            }
          }
        })
        .catch((error) => {
          common.showErrorMessage(this, error.response.data.result);
        });
      this.hideLoading();
    },
  },
};
</script>

<style></style>
