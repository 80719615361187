<template>
	<el-input class="v-captcha-input" placeholder="请输入验证码"
		:maxlength="captchaOptions.length || captchaOptionsDefault.length" v-model="value">
		<i slot="prefix" class="el-input__icon el-icon-key"></i>
		<canvas ref="captcha" :width="width" :height="height" slot="append" class="canvas"></canvas>
	</el-input>
</template>

<script>
	import CaptchaMini from "captcha-mini";
	export default {
		props: {
			captchaOptions: {
				type: Object,
				default: () => ({}),//组件传值
			},
			width: {
				type: Number,
				default: 120,
			},
			height: {
				type: Number,
				default: 40,
			},
		},
		data() {
			return {
				//用户输入的验证码
				value: '',
				//验证码对象
				captcha: null,
				//验证码
				code: '',
				//验证码默认配置
				captchaOptionsDefault: {
					lineWidth: 0.5, //线条宽度
					lineNum: 2, //线条数量
					dotR: 1, //点的半径
					dotNum: 15, //点的数量
					preGroundColor: [10, 80], //前景色区间
					backGroundColor: [150, 250], //背景色区间
					fontSize: 20, //字体大小
					fontFamily: ['Georgia', '微软雅黑', 'Helvetica', 'Arial'], //字体类型
					fontStyle: 'fill', //字体绘制方法，有fill和stroke
					content: 'acdefhijkmnpwxyABCDEFGHJKMNPQWXY12345789', //验证码内容
					length: 4 //验证码长度
				}
			}
		},
		mounted() {
			//初始化
			this.init();
			this.draw();
		},
		methods: {
			//初始化
			init() {
				this.captcha = new CaptchaMini({
					...this.captchaOptionsDefault,
					...this.captchaOptions
				})
			},
			//绘制验证码
			draw() {
				this.captcha.draw(this.$refs.captcha, (code) => {
					this.code = code;
				});
			},
			//刷新验证码
			refresh() {
				this.captcha.clear();
				this.draw()
			},
			//验证码校验
			validata() {
				//toUpperCase()全部转成小写
				return this.value.toLowerCase() == this.code.toLowerCase();
			}
		}
	}
</script>

<style lang="scss" scoped>
	:deep(.el-input-group__append) {
		padding: 0;
		border: none;
		border-radius: 0;
	}

	.canvas {
		display: block;
		cursor: pointer;
	}
</style>