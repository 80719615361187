<template>
  <div class="content">
    <h4 class="title">乐助手异常处理</h4>
    <!-- 顶部查询按钮 -->
    <el-button type="primary" plain @click="refer">查询</el-button>
    <!-- 错误信息列表 -->
    <div class="descriptions" v-for="(item, index) in err_info" :key="index">
      <el-row>
        <el-col :span="10">
          <el-descriptions :column="1" border>
            <el-descriptions-item
              label-class-name="my-label"
              content-class-name="my-content"
            >
              <template slot="label">
                <i class="el-icon-chat-dot-round"></i>
                订单号{{ index + 1 }}
              </template>
              {{ item.order_number }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-warning-outline"></i>
                错误信息
              </template>
              {{ item.state }}
            </el-descriptions-item>
          </el-descriptions>
        </el-col>
        <el-col
          :span="2"
          style="
            height: 93px;
            border-top: 1px solid #ebeef5;
            border-bottom: 1px solid #ebeef5;
          "
        >
          <!-- :plain="index % 2 == 0 ? true : false" -->
          <el-button class="alter" type="warning" plain @click="alter(index)"
            >修改</el-button
          >
        </el-col>

        <el-col
          :span="6"
          style="
            height: 93px;
            border-top: 1px solid #ebeef5;
            border-bottom: 1px solid #ebeef5;
            border-right: 1px solid #ebeef5;
            padding-right: 2vw;
          "
        >
          <el-form>
            <el-form-item class="alter" label="修改状态：" label-width="8vw"
              ><el-input v-model="state_num[index]"></el-input
            ></el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>
    <!-- 显示去重的状态 -->
    <div class="show_card">
      <el-card class="box-card" v-if="show_no_repeat">
        <div slot="header" class="clearfix">
          <span>错误状态去重结果</span>
        </div>
        <div
          v-for="(item, index) in no_repeat_state"
          :key="index"
          class="text item"
        >
          {{ `(${index + 1}) : ${item}` }}
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import * as shopFunction from "@/api/shop";
import * as common from "@/utils/common.js";
export default {
  data() {
    return {
      // 输入框
      state_num: [],
      // 获取到的错误信息
      err_info: "",
      no_repeat_state: [],
      show_no_repeat: false,
    };
  },
  created() {
    shopFunction
      .exceptLeAssistanttList()
      .then((res) => {
        this.err_info = res.data.result.data;
      })
      .catch((error) => {
        common.showErrorMessage(this, error.response.data.result);
      });
  },
  methods: {
    //显示提交
    showLoading() {
      this.loading = this.$loading({
        lock: true,
        text: "查询中！",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    },
    //隐藏提交
    hideLoading() {
      this.loading.close();
    },
    //查询
    async refer() {
      this.err_info = ""; //清空加载时查询的信息
      this.showLoading();
      await shopFunction
        .exceptLeAssistanttList()
        .then((res) => {
          this.err_info = res.data.result.data;
          // 得到去重后的状态
          this.show_no_repeat = true;
          this.no_repeat_state = Array.from(
            new Set(this.err_info.map((item) => item.state))
          );
          console.log(this.no_repeat_state);
          this.$confirm("查询成功！", "提示", {
            showCancelButton: false,
            confirmButtonText: "确定",
            type: "success",
            center: true,
          });
        })
        .catch((error) => {
          common.showErrorMessage(this, error.response.data.result);
        });
      this.hideLoading();
    },
    //修改
    async alter(index) {
      this.showLoading();
      await shopFunction
        .changeLeAssistantStatus({
          order_number: this.err_info[index].order_number,
          state: this.state_num[index] == null ? "0" : this.state_num[index],
        })
        .then((res) => {
          if (res.data.result === "ok") {
            this.$confirm("修改成功！", "提示", {
              showCancelButton: false,
              confirmButtonText: "确定",
              type: "success",
              center: true,
            });
          } else {
            this.$confirm("修改失败！", "提示", {
              showCancelButton: false,
              confirmButtonText: "确定",
              type: "error",
              center: true,
            });
          }
          this.state_num = [];
        })
        .catch((error) => {
          common.showErrorMessage(this, error.response.data.result);
        });
      this.hideLoading();
      // 修改后重新查询
      shopFunction
        .exceptLeAssistanttList()
        .then((res) => {
          this.err_info = res.data.result.data;
        })
        .catch((error) => {
          common.showErrorMessage(this, error.response.data.result);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
// 最外层盒子
.content {
  // 错误信息列表
  .descriptions {
    margin-top: 10px;

    :deep(.my-label) {
      width: 20%;
    }

    .my-content {
      width: 80%;
    }

    .alter {
      margin: 30px 0 0 20px;
      align-items: center;
    }
  }
  .show_card {
    margin-top: 20px;

    .box-card {
      width: 75%;
    }
    .text {
      font-size: 14px;
    }

    .item {
      margin-bottom: 18px;
    }
    .clearfix:before,
    .clearfix:after {
      display: table;
      content: "";
    }
    .clearfix:after {
      clear: both;
    }
  }
}
</style>
