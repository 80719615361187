export function showErrorMessage(that, msg) {
    that.$notify({
        title: "错误",
        message: msg,
        type: "error",
        duration: 5000,
        showClose: true
    });
}
export function showSuccessMessage(that, msg) {
    that.$notify({
        title: "成功",
        message: msg,
        type: "success",
        duration: 5000,
        showClose: true
    });
}
//遍历数组下的每一个对象是否为空
export function isTraverseList(list) {
    var i = 0;
    while (i < list.length) {
        for (let item in list[i]) {
            if (list[i][item] == "") {
                let result = {
                    result: false,
                    reason: null,
                    location: i,
                    item,
                };
                return result;
            }
            if (list[i][item] == []) {
                let result = {
                    result: false,
                    reason: null,
                    location: i,
                    item,
                };
                return result;
            }
            if (list[i][item] == {}) {
                let result = {
                    result: false,
                    reason: null,
                    location: i,
                    item,
                };
                return result;
            }
            if (list[i][item] == null) {
                let result = {
                    result: false,
                    reason: null,
                    location: i,
                    item,
                };
                return result;
            }
            if (list[i][item] == undefined) {
                let result = {
                    result: false,
                    reason: null,
                    location: i,
                    item,
                };
                return result;
            }
            if (list[i][item] == 0) {
                let result = {
                    result: false,
                    reason: null,
                    location: i,
                    item,
                };
                return result;
            }
        }
        i += 1;
    }

    if (i == list.length) {
        let result = {
            result: true,
        };
        return result;
    }
}

export function formattedDateTime() {
    const date = new Date();
    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
export function pad(value) {
    return value < 10 ? '0' + value : value;
}  