import Vue from 'vue'
import VueRouter from 'vue-router'
import Cms from '@/layouts/Cms'
import Login from '@/pages/Login'
import Billing from '@/pages/Billing'
import SpeedexCode from '@/pages/SpeedexCode'
import PushOrder from '@/pages/PushOrder'
import Refund from '@/pages/Refund'
import WansonErp from '@/pages/WansonErp'
import Register from '@/pages/Register'
import Exception from '@/pages/Exception'
import Upload from '@/pages/Upload'
import Download from '@/pages/Download'
import AccountManagement from '@/pages/AccountManagement'
// import Default from '@/layouts/Default'
Vue.use(VueRouter)

// push方法解决路由冗余
const routerRePush = VueRouter.prototype.push
VueRouter.prototype.push = function (location) {
	return routerRePush.call(this, location).catch(error => error)
}


// replace方法解决路由冗余
// const routerReplace = VueRouter.prototype.replace
// VueRouter.prototype.replace = function (location) {
//     return routerReplace.call(this, location).catch(error => error)
// }

//路由配置
const routes = [
	{
		path: "/",
		name: 'login',
		component: Login
	},
	{
		path: '/register',
		name: 'register',
		component: Register
	}, {
		path: '/', //路径（给'/'会优先被拦截）
		component: Cms, //组件
		children: [
			{
				path: '/billing',
				name: 'billing',
				component: Billing
			},
			{
				path: '/speedexCode',
				name: 'speedexCode',
				component: SpeedexCode
			},
			{
				path: '/pushOrder',
				name: 'pushOrder',
				component: PushOrder
			},
			{
				path: '/refund',
				name: 'refund',
				component: Refund
			},
			{
				path: '/wansonErp',
				name: 'wansonErp',
				component: WansonErp
			},
			{
				path: '/exception',
				name: 'exception',
				component: Exception
			},
			{
				path: '/upload',
				name: 'upload',
				component: Upload
			},
			{
				path: '/download',
				name: 'download',
				component: Download
			},
			{
				path: '/AccountManagement',
				name: 'accountManagement',
				component: AccountManagement
			},
		]
	}, {
		path: '*',
		redirect: '/'
	},]

//路由实例化对象
const router = new VueRouter({
	mode: 'history',
	routes
});

//配置全局前置守卫
/**
 * to:去哪 目标路由
 * from:来自哪 源路由
 * next:继续 (放行)
 */
// router.beforeEach((to, from, next) => {
// 	//如果进入登录页,放行
// 	if(to.name == 'login'){
// 		next();
// 	}
// 	//如果进入非登录页,则跳转到登录页
// 	else{
// 		next();
// 	}
// });

//路由导出
export default router