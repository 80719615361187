<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/style.scss";
@import "@/icons/iconfont/iconfont.css";
</style>