<template>
  <div>
    <el-row type="flex" justify="center" align="middle">
      <el-col :xs="22" :sm="20" :md="14" :lg="10" :xl="7">
        <div class="main">
          <h1>账号注册</h1>
          <el-form :model="form_data" :rules="rules" ref="form_data">
            <el-form-item prop="user_name">
              <el-input
                prefix-icon="el-icon-user"
                v-model="form_data.user_name"
                placeholder="请输入用户名"
              ></el-input>
            </el-form-item>
            <el-form-item prop="my_name">
              <el-input
                prefix-icon="el-icon-user-solid"
                v-model="form_data.my_name"
                placeholder="请输入您的真实姓名"
              ></el-input>
            </el-form-item>
            <el-form-item prop="user_password">
              <el-input
                show-password
                prefix-icon="el-icon-lock"
                v-model="form_data.user_password"
                placeholder="请输入您的密码"
              ></el-input>
            </el-form-item>
            <el-form-item prop="user_phone">
              <el-input
                prefix-icon="el-icon-phone-outline"
                v-model="form_data.user_phone"
                placeholder="请输入您的手机号码"
              ></el-input>
            </el-form-item>
            <el-form-item prop="department">
              <el-select
                style="width: 100%"
                v-model="form_data.department"
                placeholder="请选择部门"
                @change="changeDepartment"
              >
                <el-option
                  v-for="(item, index) in department_list"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="staff">
              <el-select
                style="width: 100%"
                v-model="form_data.staff"
                placeholder="请选择岗位"
                @change="changeStaff"
              >
                <el-option
                  v-for="(item, index) in staff_list"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="register('form_data')"
                >注册</el-button
              >
            </el-form-item>
            <el-form-item>
              <span class="login" @click="goToLogin">立即登录</span>
            </el-form-item>
          </el-form>
          <div class="copy">©云南宏宛松商贸有限公司</div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
  
<script>
import * as shopFunction from "@/api/shop";
export default {
  name: "Register",
  data() {
    return {
      department: "",
      // 部门列表
      department_list: [],
      // 岗位列表
      staff_list: [],
      // 表单校验规则
      rules: {
        user_name: [
          { required: true, message: "用户名不能为空", trigger: "blur" },
          {
            min: 4,
            max: 16,
            message: "长度在 4 到 16 个字符",
            trigger: "blur",
          },
        ],
        my_name: [
          { required: true, message: "姓名不能为空", trigger: "blur" },
          { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        user_password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
        ],
        user_phone: [
          { required: true, message: "电话号码不能为空", trigger: "blur" },
        ],
      },
      form_data: {
        user_name: "",
        my_name: "",
        user_password: "",
        user_phone: "",
        department: "",
        staff: "",
      },
      loading: null,
    };
  },
  created() {
    this.getDepartmentList();
  },
  methods: {
    changeStaff(e) {
      // console.log(e);
    },
    changeDepartment(e) {
      this.taff_list = [];
      for (var i = 0; i < this.department.length; i++) {
        //解析全局数据
        //判断用户选择谁,然后解析谁的数据
        if (this.department_list[i].value == e) {
          for (var j = 0; j < this.department[i].child_list.length; j++) {
            //构造uniapp select样式数据
            this.staff_list.push({
              label: this.department[i].child_list[j].staff_name,
              value: this.department[i].child_list[j].id,
            });
          }
        }
      }
    },
    //请求拿到部门与岗位
    async getDepartmentList() {
      await shopFunction.getAllDepartmentList().then((res) => {
        let department = res.data.department_list;
        this.department = department;
        //循环得到部门名称
        for (var i = 0; i < department.length; i++) {
          //这里是拆解的部门名称,给部门选择
          this.department_list.push({
            label: department[i].department_name,
            value: department[i].department_id,
          });
        }
      });
    },
    //注册事件
    register(form_data) {
      this.$refs[form_data].validate((valid) => {
        if (valid) {
          if (this.form_data.department == "") {
            this.$message({
              type: "error",
              message: "请选择部门！",
              center: true,
            });
          } else if (this.form_data.staff == "") {
            this.$message({
              type: "error",
              message: "请选择岗位！",
              center: true,
            });
          } else {
            shopFunction
              .registerAccount({
                user_form_data: {
                  user_name: this.form_data.user_name,
                  my_name: this.form_data.my_name,
                  user_password: this.form_data.user_password,
                  user_phone: this.form_data.user_phone,
                  department: this.form_data.department,
                  staff: this.form_data.staff,
                },
              })
              .then((res) => {
                if (res.data.errno === "ok") {
                  this.$message({
                    type: "success",
                    message: "注册成功！",
                    center: true,
                  });
                  setTimeout(() => {
                    this.$router.push({
                      path: "/erp",
                    });
                  }, 1500);
                } else {
                  this.$message({
                    type: "error",
                    message: res.result,
                    center: true,
                  });
                }
              });
          }
        } else {
          return false;
        }
      });
    },
    //注册跳转
    goToLogin() {
      this.$router.push("/");
    },

    //显示加载
    showLoading() {
      this.loading = this.$loading({
        lock: true,
        text: "登录中！",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    },
    //隐藏加载
    hideLoading() {
      this.loading.close();
    },
  },
};
</script>
  
<style lang="scss" scoped>
.el-row {
  min-height: 100vh;
  // min-width: 100vw;
  background: #3175bb;

  .el-col {
    .main {
      background: #79bbff;
      padding: 50px;
      color: #fff;
      border-radius: 10px;

      .el-form {
        .el-form-item {
          .login {
            cursor: pointer;
          }
          .el-button {
            width: 100%;
          }
        }
      }

      h1 {
        text-align: center;
      }
    }

    .copy {
      text-align: center;
      color: #fff;
    }
  }
}
</style>