<template>
  <!-- 最外层盒子 -->
  <el-container class="container">
    <!-- 左侧导航栏展开 -->
    <el-container class="left" v-if="unfold">
      <!-- 左侧导航栏头部 -->
      <el-header class="left-header">
        <div class="title">
          <i class="el-icon-s-platform logo"></i>
          <h3>宛松ERP</h3>
        </div>
      </el-header>
      <!-- 左侧导航栏内容展开 -->
      <el-main class="left-main">
        <el-menu
          class="menu"
          background-color="#79BBFF"
          text-color="#FFFFFF"
          active-text-color="#000000"
        >
          <!-- <el-menu-item @click="goToIndex">
            <template slot="title">
              <i class="el-icon-s-home icon"></i>
              <span>首页</span>
            </template>
          </el-menu-item> -->
          <el-submenu index="0">
            <template slot="title">
              <i class="el-icon-s-promotion icon"></i>
              <span>ERP订单</span>
            </template>
            <el-menu-item @click="goToPushOrder">
              <template slot="title">
                <i class="el-icon-s-order icon"></i>
                <span>宛松商城订单</span>
              </template>
            </el-menu-item>
            <el-menu-item @click="goToBilling">
              <template slot="title">
                <i class="el-icon-s-order icon"></i>
                <span>其他推单</span>
              </template>
            </el-menu-item>
            <el-menu-item @click="goToSpeedexCode">
              <template slot="title">
                <i class="el-icon-s-order icon"></i>
                <span>编码匹配</span>
              </template>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="1">
            <template slot="title">
              <i class="el-icon-s-shop icon"></i>
              <span>宛松助手ERP</span>
            </template>
            <el-menu-item @click="goToWanSonErp">
              <template slot="title">
                <i class="el-icon-edit icon"></i>
                <span>重新推单</span>
              </template>
            </el-menu-item>
            <el-menu-item @click="goToDownload">
              <template slot="title">
                <i class="el-icon-download icon"></i>
                <span>订单下载</span>
              </template>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="2">
            <template slot="title">
              <i class="el-icon-s-check icon"></i>
              <span>乐助手</span>
            </template>
            <el-menu-item @click="goToException">
              <template slot="title">
                <i class="el-icon-warning icon"></i>
                <span>异常处理</span>
              </template>
            </el-menu-item>
            <el-menu-item @click="goToUpload">
              <template slot="title">
                <i class="el-icon-upload icon"></i>
                <span>上传会销文件</span>
              </template>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="3">
            <template slot="title">
              <i class="el-icon-s-management icon"></i>
              <span>后台管理</span>
            </template>
            <el-menu-item @click="goToAccountManagement">
              <template slot="title">
                <i class="el-icon-user-solid icon"></i>
                <span>账号管理</span>
              </template>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-main>
    </el-container>
    <!-- 左侧导航栏折叠 -->
    <el-container class="left" v-if="fold">
      <!-- 左侧导航栏头部 -->
      <el-header class="left-header">
        <div class="title" style="height: 60px">
          <i class="el-icon-s-platform logo"></i>
        </div>
      </el-header>
      <!-- 左侧导航栏内容展开 -->
      <el-main class="left-main">
        <el-menu
          style="width: 85px"
          class="menu"
          background-color="#79BBFF"
          text-color="#FFFFFF"
          active-text-color="#000000"
        >
          <!-- <el-menu-item @click="goToIndex">
            <template slot="title">
              <i class="el-icon-s-home icon"></i>
              <span>首页</span>
            </template>
          </el-menu-item> -->
          <el-submenu index="0">
            <template slot="title">
              <i class="el-icon-s-promotion icon"></i>
              <span>ERP</span>
            </template>
            <el-menu-item @click="goToPushOrder">
              <template slot="title">
                <i class="el-icon-s-order icon"></i>
                <span>宛松商城订单</span>
              </template>
            </el-menu-item>
            <el-menu-item @click="goToBilling">
              <template slot="title">
                <i class="el-icon-s-order icon"></i>
                <span>其他推单</span>
              </template>
            </el-menu-item>
            <el-menu-item @click="goToSpeedexCode">
              <template slot="title">
                <i class="el-icon-s-order icon"></i>
                <span>编码匹配</span>
              </template>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="1">
            <template slot="title">
              <i class="el-icon-s-shop icon"></i>
              <span>宛松助手ERP</span>
            </template>
            <el-menu-item @click="goToWanSonErp">
              <template slot="title">
                <i class="el-icon-edit icon"></i>
                <span>重新推单</span>
              </template>
            </el-menu-item>
            <el-menu-item @click="goToDownload">
              <template slot="title">
                <i class="el-icon-download icon"></i>
                <span>订单下载</span>
              </template>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="2">
            <template slot="title">
              <i class="el-icon-s-check icon"></i>
              <span>乐助手</span>
            </template>
            <el-menu-item @click="goToException">
              <template slot="title">
                <i class="el-icon-warning icon"></i>
                <span>异常处理</span>
              </template>
            </el-menu-item>
            <el-menu-item @click="goToUpload">
              <template slot="title">
                <i class="el-icon-upload icon"></i>
                <span>上传会销文件</span>
              </template>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="3">
            <template slot="title">
              <i class="el-icon-s-management icon"></i>
              <span>后台管理</span>
            </template>
            <el-menu-item @click="goToAccountManagement">
              <template slot="title">
                <i class="el-icon-user-solid icon"></i>
                <span>账号管理</span>
              </template>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-main>
    </el-container>
    <!-- 右侧导航栏 -->
    <el-container class="right">
      <!-- 右侧导航栏 -->
      <el-header class="right-header">
        <el-row type="flex" justify="space-between">
          <el-col span="auto" class="controller">
            <i
              class="el-icon-s-unfold controller-icon"
              style="line-height: 60px; cursor: pointer"
              @click="foldClick"
            ></i>
            <!-- <el-breadcrumb separator="/" class="controller-breadcrumb">
							<el-breadcrumb-item>首页</el-breadcrumb-item>
						</el-breadcrumb> -->
          </el-col>
          <el-col span="auto" class="user-info">
            <el-menu
              mode="horizontal"
              background-color="#79BBFF"
              text-color="#FFFFFF"
              active-text-color="#000000"
            >
              <el-submenu index="">
                <template slot="title">
                  <el-avatar
                    class="avater"
                    size="small"
                    src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"
                  ></el-avatar>
                  <!-- <span>{{username}}</span> -->
                </template>
                <el-menu-item>
                  <template slot="title">
                    <i class="el-icon-s-custom"></i>
                    <span>个人资料</span>
                  </template>
                </el-menu-item>
                <el-menu-item @click="exit">
                  <template slot="title">
                    <i class="el-icon-switch-button"></i>
                    <span>退出</span>
                  </template>
                </el-menu-item>
              </el-submenu>
            </el-menu>
          </el-col>
        </el-row>
      </el-header>
      <!-- 右侧内容 -->
      <el-main class="right-main">
        <router-view></router-view>
      </el-main>
      <!-- <el-footer style="background: #fff">
        <el-pagination style="text-align: center;" small background layout="prev, pager, next" :total="100">
        </el-pagination>
      </el-footer> -->
    </el-container>
  </el-container>
</template>

<script>
export default {
  name: "CMS",
  data() {
    return {
      fold: false,
      unfold: true,
    };
  },
  onLoad() {},
  mounted() {
    // let user = JSON.parse(sessionStorage.getItem('user'))
    // this.username = user;
  },
  methods: {
    exit() {
      this.$router.push({ name: "login" });
    },
    goToBilling() {
      this.$router.push({ name: "billing" });
    },
    goToSpeedexCode() {
      this.$router.push({ name: "speedexCode" });
    },
    goToPushOrder() {
      this.$router.push({ name: "pushOrder" });
    },
    goToWanSonErp() {
      this.$router.push({ name: "wansonErp" });
    },
    goTorefund() {
      this.$router.push({ name: "refund" });
    },
    goToException() {
      this.$router.push({ name: "exception" });
    },
    goToUpload() {
      this.$router.push({ name: "upload" });
    },
    goToDownload() {
      this.$router.push({ name: "download" });
    },
    goToResetPassword() {
      this.$router.push({ name: "resetpassword" });
    },
    goToAccountManagement() {
      this.$router.push({ name: "accountManagement" });
    },
    foldClick() {
      this.unfold = !this.unfold;
      this.fold = !this.fold;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  // height: 100%;
  min-height: 100vh;
  .left {
    flex: 0 0 auto;

    .left-header {
      background: #53a8ff;

      .title {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5vw;

        h3 {
          color: #fff;
        }

        .logo {
          color: #fff;
          font-size: 1.5vw; //1rem=16px
        }
      }
    }

    .left-main {
      margin-top: 1px;
      background: #66b1ff;
      height: 100%;

      .menu {
        min-width: 80px;
        .el-menu-item {
          min-width: 80px;
          .icon {
            color: #fff;
          }
        }

        .el-submenu {
          min-width: 80px;
          .icon {
            color: #fff;
          }
        }
      }

      :deep(.menu) {
        width: 12vw;
        border-right: 0;
        // background: #66B1FF;
      }
    }
  }

  .right {
    .right-header {
      background: #79bbff;

      .controller {
        display: flex;
        align-items: center;
        justify-content: center;

        .controller-icon {
          font-size: 1.5rem;
          margin-right: 1.5rem;
          color: #fff;
        }

        .controller-breadcrumb {
          .el-breadcrumb-item {
            background: #fff;
          }
        }
      }

      .user-info {
        .el-menu {
          .el-submenu {
            .avater {
              margin-right: 0.5rem;
            }
          }
        }

        :deep(.el-menu.el-menu--horizontal) {
          border-bottom: 0;
        }
      }
    }
    .right-main {
      margin: 3vh 1vw;
      padding: 1px 0 0 1px;
    }
  }
}
</style>