<template>
  <div class="content-box">
    <div class="main">
      <!-- 顶部搜索框及按钮 -->
      <el-row style="margin-bottom: 10px">
        <el-col :span="6">
          <el-button
            type="primary"
            size="mini"
            plain
            @click.native="submitmergeOrder()"
            >合单</el-button
          >
          <el-button
            type="primary"
            size="mini"
            plain
            @click.native="syncOrders(0)"
            >同步订单</el-button
          >
          <el-button
            type="primary"
            size="mini"
            plain
            @click.native="syncOrders(1)"
            >首次同步订单</el-button
          >
        </el-col>
        <!-- <span><span style="color: red;">[ERP公告]:</span><span style="color: aliceblue;background-color: #3996f3;">由于订单折扣问题和财务沟通结果:不希望有小数点问题;ERP合单功能按钮暂停使用,等技术解决了在开通合单功能;其他功能不受影响!
          [信息部] 2024-01-16</span></span> -->
      </el-row>
      <el-row>
        <el-col :span="3">
          <el-select
            size="small"
            v-model="search_state_value"
            placeholder="请选择订单状态"
            @change="searchState()"
          >
            <el-option
              v-for="item in state_list"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-input
            v-model="search_order_value"
            size="small"
            placeholder="请输入单号"
            suffix-icon="el-icon-search"
            >搜索</el-input
          >
        </el-col>
        <el-col :span="2">
          <el-button
            size="small"
            style="margin-left: 10px"
            type="success"
            icon="el-icon-search"
            circle
            @click="search()"
          ></el-button>
        </el-col>
      </el-row>
      <!-- 订单列表 -->
      <el-table
        @select="handleSelectionChange"
        :header-cell-style="{ background: '#FAFAFA' }"
        border
        :data="order_list"
        style="margin-top: 20px; width: 80vw"
      >
        <el-table-column type="selection" width="50"></el-table-column>
        <el-table-column label="订单号" width="150" header-align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.order_no }}</span>
          </template>
        </el-table-column>

        <el-table-column label="收货人" width="70" header-align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.receiver_name }}</span>
          </template>
        </el-table-column>

        <el-table-column label="收货人电话" width="110" header-align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.receiver_mobile }}</span>
          </template>
        </el-table-column>
        <el-table-column label="收货地址" width="400" header-align="center">
          <template slot-scope="scope">
            <span>{{
              scope.row.receiver_province +
              scope.row.receiver_city +
              scope.row.receiver_area +
              scope.row.receiver_address
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="商品数量" width="60" header-align="center">
          <template slot-scope="scope">
            <span>{{ addNum(scope.row.order_child_list) }}</span>
          </template>
          {{ order_child_list.length }}
        </el-table-column>
        <el-table-column label="下单时间" width="100" header-align="center">
          <template slot-scope="scope">
            <!-- <i class="el-icon-time"></i> -->
            <span>{{ scope.row.create_time }}</span>
          </template>
        </el-table-column>
        <el-table-column label="微分销状态" width="110" header-align="center">
          <template slot-scope="scope">
            <el-tag
              :type="
                scope.row.status.choices_name == '等待卖家发货'
                  ? 'danger'
                  : 'success'
              "
            >
              <span>{{ scope.row.status.choices_name }}</span>
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="ERP状态" width="60" header-align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.is_take_effect.choices_name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="详情" width="180" header-align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="orderInfo(scope.row.id)"
              type="primary"
              plain
              >详情</el-button
            >
            <el-button
              size="mini"
              @click="
                sendGoods(
                  scope.row.id,
                  scope.row.status.choices_name,
                  scope.row.is_take_effect.choices_name
                )
              "
              type="primary"
              plain
              >发货</el-button
            >

            <div v-if="scope.row.additional_info.length > 0">
              <!-- <div v-if="scope.row."></div> -->
              <el-button
                size="mini"
                @click="
                  revokeSplitOrder(
                    scope.row.additional_info[0].operation_attribute == 0
                      ? 'Y,' + scope.row.additional_info[0].original_order
                      : 'X,' + scope.row.additional_info[0].new_order
                  )
                "
                type="danger"
                icon="iconfont icon-undo"
                circle
                style="margin-left: 10px"
              ></el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作记录" width="310" header-align="center">
          <template slot-scope="scope">
            <div style="margin-left: 15px">
              <div v-if="scope.row.additional_info.length > 0">
                <ol style="padding: 0; margin: 0">
                  <li>{{ scope.row.additional_info[0].notes }}</li>
                  <div
                    v-for="(item, index) in scope.row.operation_record"
                    :key="index"
                  >
                    <li>
                      {{ item.operation_content }},{{ item.operation_date }}
                    </li>
                  </div>
                </ol>
              </div>
              <div v-else>
                <ol style="padding: 0; margin-left: -15px">
                  <div
                    v-for="(item, index) in scope.row.operation_record"
                    :key="index"
                  >
                    <li>
                      {{ item.operation_content }},{{ item.operation_date }}
                    </li>
                  </div>
                </ol>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="销货单号" width="140" header-align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.sales_order_number }}</span>
          </template>
        </el-table-column>
        <el-table-column label="出库单号" width="140" header-align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.outbound_order_number }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="currentChange"
        :current-page="currentTotalPages"
        :page-sizes="[50, 100, 200]"
        :page-size="50"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 编辑 -->
    <div class="dialog-edit">
      <el-dialog title="编辑订单信息" :visible.sync="dialog_edit" width="50%">
        <el-form :inline="true" label-width="80px">
          <el-form-item label="订单号">
            <el-input disabled v-model="edit_data.order_no"></el-input>
          </el-form-item>
          <el-form-item label="下单时间">
            <el-input disabled v-model="edit_data.create_time"></el-input>
          </el-form-item>
        </el-form>
        <el-form
          label-width="80px"
          :model="edit_data"
          :rules="rules"
          ref="edit_data"
        >
          <el-form-item label="姓名" prop="name">
            <el-input
              @input="nameInput"
              placeholder="请输入姓名"
              v-model="edit_data.receiver_name"
            ></el-input>
          </el-form-item>
          <el-form-item label="电话" prop="phone">
            <el-input
              @input="phoneInput"
              placeholder="请输入电话"
              v-model="edit_data.receiver_mobile"
            ></el-input>
          </el-form-item>
          <el-form-item label="省" prop="address">
            <el-input
              @input="provinceInput"
              placeholder="请输入省"
              v-model="edit_data.receiver_province"
            ></el-input>
          </el-form-item>
          <el-form-item label="市" prop="address">
            <el-input
              @input="provinceInput"
              placeholder="请输入市"
              v-model="edit_data.receiver_city"
            ></el-input>
          </el-form-item>
          <el-form-item label="区" prop="address">
            <el-input
              @input="provinceInput"
              placeholder="请输入区"
              v-model="edit_data.receiver_area"
            ></el-input>
          </el-form-item>

          <el-form-item label="详细地址">
            <el-input
              @input="addressInput"
              placeholder="详细地址"
              v-model="edit_data.receiver_address"
            ></el-input>
          </el-form-item>
          <el-form-item label="仓库" prop="warehouse" required>
            <el-select
              v-model="edit_data.warehouse"
              placeholder="请选择仓库"
              @change="storeChange"
            >
              <el-option
                v-for="item in warehouse_list"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <div v-if="edit_data.warehouse == 2">
            <el-form-item
              label="配送方式"
              prop="speedex_delivery_model"
              required
            >
              <el-select
                v-model="edit_data.speedex_delivery_model"
                placeholder="请选择配送方式"
              >
                <el-option
                  v-for="item in speedex_model_list"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <el-form-item label="备注">
            <el-input
              placeholder="请输入备注"
              type="textarea"
              v-model="edit_data.remark"
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialog_edit = false">取 消</el-button>
          <el-button
            type="primary"
            @click="submitDialog('edit_data', edit_data.id)"
            >保 存</el-button
          >
        </span>
      </el-dialog>
    </div>
    <!-- 详情 -->
    <div>
      <el-dialog
        :visible.sync="drawer"
        title="订单操作"
        style="font-weight: bold"
        :modal="false"
      >
        <el-row type="flex" justify="start">
          <el-button
            type="primary"
            @click="pushingMallOrder()"
            icon="iconfont icon-chanjet"
          >
            生成T+单</el-button
          >
          <el-button
            type="primary"
            @click="pushingSpeedexOrder()"
            icon="iconfont icon-express"
          >
            生成速必达单</el-button
          >
          <el-button
            type="primary"
            @click="generateMessageTemplate()"
            icon="iconfont icon-message"
          >
            生成消息模板</el-button
          >
          <el-button type="danger" @click="oneClickPushOrder()"
            >生成所有订单</el-button
          >
          <!-- <el-button type="primary">推单到云聚</el-button>-->
        </el-row>
        <el-divider></el-divider>
        <el-row type="flex" justify="center" style="margin-top: 20px">
          <el-col :span="24">
            <el-descriptions
              title="订单详情"
              direction="horizontal"
              :column="3"
              border
            >
              <template slot="extra">
                <el-button type="success" @click="editOrderInfo(order_info.id)"
                  >编辑</el-button
                >
              </template>
              <el-descriptions-item>
                <template slot="label">订单号</template>
                {{ order_info.order_no }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">下单时间</template>
                {{ order_info.create_time }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">支付方式</template>
                {{ order_info.pay_type }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">客户ID</template>
                {{ order_info.user_id }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">业务员ID</template>
                {{ order_info.three_dls_id }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">业务上级ID</template>
                {{ order_info.top_dls_id }}
              </el-descriptions-item>

              <el-descriptions-item label="收货人">
                {{ order_info.receiver_name }}
              </el-descriptions-item>
              <el-descriptions-item label="收货人手机">
                {{ order_info.receiver_mobile }}
              </el-descriptions-item>

              <el-descriptions-item label="收货地址">
                {{
                  order_info.receiver_province +
                  order_info.receiver_city +
                  order_info.receiver_area +
                  order_info.receiver_address
                }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">实付金额</template>
                {{ order_info.payment }}
              </el-descriptions-item>
              <el-descriptions-item label="发货仓库">
                <div
                  v-for="item in warehouse_list"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                  <div v-if="item.value == order_info.warehouse">
                    {{ item.label }}
                  </div>
                </div>
              </el-descriptions-item>
              <el-descriptions-item label="配送方式(速)">
                {{ order_info.speedex_delivery_model }}
              </el-descriptions-item>
            </el-descriptions>
            <el-descriptions :column="1" border>
              <el-descriptions-item label="发货备注">
                {{ order_info.remark }}
              </el-descriptions-item>
            </el-descriptions>

            <el-divider></el-divider>
            <el-descriptions title="商品明细">
              <template slot="extra">
                <el-button type="success" @click="chaiButton">拆单</el-button>
              </template>
            </el-descriptions>
            <el-table
              :header-cell-style="{ background: '#FAFAFA' }"
              border
              :data="order_child_list"
              ref="myOrderTable"
            >
              <el-table-column
                v-if="false"
                type="index"
                width="50"
              ></el-table-column>
              <el-table-column label="图片" width="80" header-align="center">
                <template slot-scope="scope">
                  <el-image :src="scope.row.item_img"></el-image>
                </template>
              </el-table-column>
              <el-table-column
                label="机器名称"
                width="180"
                header-align="center"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.item_title }}</span>
                </template>
              </el-table-column>
              <el-table-column label="机器编号" header-align="center">
                <template slot-scope="scope">
                  <span>{{ scope.row.goods_no }}</span>
                </template>
              </el-table-column>
              <el-table-column label="价格" width="100" header-align="center">
                <template slot-scope="scope">
                  <span>{{ "￥" + scope.row.current_price }}</span>
                </template>
              </el-table-column>
              <el-table-column label="数量" width="60" header-align="center">
                <template slot-scope="scope">
                  <span>{{ scope.row.num }}</span>
                </template>
              </el-table-column>
              <el-table-column label="库存" header-align="center">
                <el-table-column
                  label="邦盛新库"
                  width="100"
                  header-align="center"
                >
                  <template slot-scope="scope">
                    <el-tag type="success" size="small"
                      >{{ scope.row.bs_num }}
                    </el-tag>
                  </template>
                </el-table-column>
                <el-table-column
                  label="公家村总仓"
                  width="100"
                  header-align="center"
                >
                  <template slot-scope="scope">
                    <el-tag type="success" size="small"
                      >{{ scope.row.gjc_num }}
                    </el-tag>
                  </template>
                </el-table-column>
                <el-table-column
                  label="中转仓"
                  width="100"
                  header-align="center"
                >
                  <template slot-scope="scope">
                    <el-tag type="success" size="small"
                      >{{ scope.row.zzc_num }}
                    </el-tag>
                  </template>
                </el-table-column>
              </el-table-column>
            </el-table>
            <el-row style="margin: 20px; float: right">
              总金额: <span style="color: red">{{ total_amount }}</span> 元 ,
              总数量: <span style="color: red">{{ model_count }}</span> 台
            </el-row>
          </el-col>
        </el-row>
        <!-- 生成消息模板 -->
        <div>
          <el-drawer
            title="消息模版"
            :visible.sync="messageDrawer"
            direction="rtl"
            :modal="false"
          >
            <div style="margin: 0 1.5rem">
              <el-divider></el-divider>
              <div v-for="(item, index) in messageTemplate" :key="index">
                <span>订单类型：{{ item.订单类型 }}</span>
                <br />
                <span>客户委托号：{{ item.客户委托号 }}</span>
                <br />
                <span>收货联系人：{{ item.收货联系人 }}</span>
                <br />
                <span>收货联系人手机：{{ item.收货联系人手机 }}</span>
                <br />
                <span>收货地址：{{ item.收货地址 }}</span>
                <br />
                <div
                  v-for="(current_module, i) in messageTemplate[index].型号"
                  :key="i"
                >
                  型号：
                  <br />
                  <span>商品名称：{{ current_module.商品名称 }}</span
                  ><span> 数量：{{ current_module.数量 }}</span>
                  <br />
                  <span>客户商品编码：{{ current_module.客户商品编码 }}</span>
                  <br />
                </div>
                <span>订单备注：{{ item.订单备注 }}</span>
                <br />
              </div>
            </div>
          </el-drawer>
        </div>
      </el-dialog>
    </div>
    <!-- 拆单操作 -->
    <div>
      <el-dialog
        :visible.sync="dialogChaiTableVisible"
        class="chai-dialog"
        center
      >
        <span
          slot="title"
          style="color: red; font-family: Arial, sans-serif; font-size: 20px"
          >拆单操作</span
        >
        <!-- 原始订单型号 -->
        <h3>原订单</h3>
        <el-table :data="order_child_list" border>
          <el-table-column
            property="order_number"
            label="订单号"
            width="150"
            header-align="center"
          ></el-table-column>
          <el-table-column
            property="item_title"
            label="名称"
            width="200"
            header-align="center"
          ></el-table-column>
          <el-table-column
            property="goods_no"
            label="工厂编码"
            width="120"
            header-align="center"
          ></el-table-column>
          <el-table-column
            property="num"
            label="数量"
            align="center"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            property="bs_num"
            label="邦盛新库"
            align="center"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            property="gjc_num"
            label="公家村总仓"
            align="center"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            property="zzc_num"
            label="中转仓"
            align="center"
            header-align="center"
          >
          </el-table-column>
          <el-table-column label="拆单操作">
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="mini"
                @click="handleChange(scope.$index)"
                >拆出</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <h3>拆后订单</h3>
        <el-table :data="chai_child_list" border>
          <el-table-column
            property="order_number"
            label="订单号"
            width="150"
            header-align="center"
          ></el-table-column>
          <el-table-column
            property="item_title"
            label="名称"
            width="200"
            header-align="center"
          ></el-table-column>
          <el-table-column
            property="goods_no"
            label="工厂编码"
            width="120"
            header-align="center"
          ></el-table-column>
          <el-table-column
            property="num"
            label="数量"
            align="center"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            property="bs_num"
            label="邦盛新库"
            align="center"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            property="gjc_num"
            label="公家村总仓"
            align="center"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            property="zzc_num"
            label="中转仓"
            align="center"
            header-align="center"
          >
          </el-table-column>
          <el-table-column label="拆回操作">
            <template slot-scope="scope">
              <el-button
                type="success"
                size="mini"
                @click="chaiChange(scope.$index)"
                >拆回</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div style="height: 60px">
          <el-button
            type="success"
            style="margin: 20px; float: right"
            @click="submitChaiData"
            >提交</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import * as shopFunction from "@/api/shop";
import * as common from "@/utils/common.js";
export default {
  data() {
    return {
      dialogChaiTableVisible: false,
      disable: false,
      isCancel: false,
      num_step: 1,
      push_order: false,
      edit_data: {},
      rules: {
        warehouse: [
          { required: true, message: "请选择仓库", trigger: "change" },
        ],
        speedex_delivery_model: [
          { required: true, message: "请选择配送模式", trigger: "change" },
        ],
      },
      report_yunju: [
        {
          value: true,
          label: "是",
        },
        {
          value: false,
          label: "否",
        },
      ],
      state_list: [
        {
          value: "0",
          label: "等待买家付款",
        },
        {
          value: "1",
          label: "等待卖家发货",
        },
        {
          value: "2",
          label: "卖家已发货",
        },
        {
          value: "3",
          label: "交易成功",
        },
        {
          value: "4",
          label: "付款前,关闭交易",
        },
        {
          value: "5",
          label: "用户退款成功,自动关闭",
        },
        {
          value: "6",
          label: "用户退款中",
        },
      ],
      search_order_value: "", //通过订单号查询
      search_state_value: "", // 订单状态选择
      warehouse_list: [],
      speedex_model_list: [],
      drawer: false, // 详情弹窗
      messageDrawer: false, //消息模板抽屉
      dialog_edit: false, // 编辑弹窗
      order_list: [], // 订单列表
      order_child_list: [], //子订单列表
      chai_child_list: {},
      order_info: "", //详情页头部信息
      total: 0, //数据总条数
      currentTotalPages: 0, //当前共有多少页
      currentCount: 50, //当前页显示多少条
      useCurrentPage: 1, //当前使用的页号
      loading: null, //加载遮罩层
      clickTimeout: null,
      return_data: "",
      H_data_list: "",
      model_count: 0,
      total_amount: 0,
      new_order_code: "",
      old_order_code: "",
      chai_order_code: [],
      messageTemplate: [],
    };
  },

  created() {
    this.getSpeedexModel();
    this.getWarehouseList(); //获取仓库列表
    this.searchState();
  },
  methods: {
    //未选择公家村仓库,配送方式为空
    storeChange(newVal) {
      if (newVal !== 2) {
        this.edit_data.speedex_delivery_model = "";
      }
    },
    // 获取仓库列表
    getWarehouseList() {
      shopFunction
        .getShopWarehouseList()
        .then((res) => {
          for (var i = 0; i < res.data.results.length; i++) {
            // console.log("仓库列表获取成功");
            //这里是拆解的部门名称,给部门选择
            this.warehouse_list.push({
              label: res.data.results[i].name,
              value: res.data.results[i].id,
            });
          }
        })
        .catch((error) => {
          common.showErrorMessage(this, error.response.data.result);
        });
    },
    //(固)速必达配送模式
    getSpeedexModel() {
      shopFunction
        .speedexModel()
        .then((res) => {
          for (let i = 0; i < res.data.results.length; i++) {
            this.speedex_model_list.push({
              label: res.data.results[i].mode,
              value: res.data.results[i].mode,
            });
          }
        })
        .catch((error) => {
          common.showErrorMessage(this, error.response.data.result);
        });
    },

    //订单号搜索订单
    searchOrderNo() {
      shopFunction
        .getPaginationOrder({ order_no: this.search_order_value })
        .then((res) => {
          this.order_list = res.data.results;
          console.log(res.data);
          this.total = res.data.count;
        })
        .catch((error) => {
          common.showErrorMessage(this, error.response.data.result);
        });
    },
    // 点击搜索
    search() {
      this.searchOrderNo();
    },
    //订单列表
    getOrderList() {
      let orderList = shopFunction.getShopOrderList().catch((error) => {
        common.showErrorMessage(this, error.response.data.result);
      });
      orderList.then((res) => {
        this.order_list = res.data.results;
        this.total = res.data.count; //分页总页数
      });
    },
    handleSizeChange(val) {
      //控制当前每页显示多少条
      this.currentCount = val;
      this.requestPage(1);
      // console.log(`每页 ${val} 条`);
    },
    currentChange(e) {
      this.requestPage(e);
    },
    async requestPage(page) {
      this.order_list = [];
      this.useCurrentPage = page;
      // console.log("当前设置每页显示:" + this.currentCount);
      // console.log("当前页号:" + page);
      const current_status =
        this.search_state_value === "" ? 1 : this.search_state_value;
      try {
        await shopFunction
          .getPaginationOrder({
            page: page,
            status: current_status,
            page_size: this.currentCount,
          })
          .then((res) => this.updateOrderList(res));
      } catch (error) {
        common.showErrorMessage(this, error.response.data.result);
      }
    },
    async searchState() {
      try {
        const current_status =
          this.search_state_value === "" ? 1 : this.search_state_value;
        const res = await shopFunction.getPaginationOrder({
          status: current_status,
        });
        this.updateOrderList(res);
      } catch (error) {
        common.showErrorMessage(this, error.response.data.result);
      }
    },

    updateOrderList(res) {
      // console.log(res);
      this.order_list = res.data.results;
      this.total = res.data.count;
    },

    //单个订单
    async getOrderChildList(id) {
      try {
        let res = await shopFunction
          .getAloneOrder(id)
          .then()
          .catch((error) => {
            common.showErrorMessage(this, error.response.data.result);
          });
        this.order_info = res.data;
        const temp_child_list = res.data.order_child_list;
        this.edit_data = res.data;
        // console.log(this.edit_data);
        this.showLoading();
        //型号数量叠加
        let current_num = 0;
        let new_payment = 0;
        for (let index = 0; index < res.data.order_child_list.length; index++) {
          // 商品应付总金额计算
          new_payment +=
            res.data.order_child_list[index].current_price *
            res.data.order_child_list[index].num;
          // 这里不能使用强转整数类型，因为会丢失小数点
          // parseInt(res.data.order_child_list[index].current_price) *
          // res.data.order_child_list[index].num;

          current_num += res.data.order_child_list[index].num;
        }
        this.total_amount = new_payment;
        this.model_count = current_num;
        //逐一查询单个库存
        for (let i = 0; i < temp_child_list.length; i++) {
          await shopFunction
            .getCurrentInventory({
              code: temp_child_list[i].goods_no,
            })
            .then((res) => {
              let childRes = res.data;
              temp_child_list[i].bs_num = 0;
              temp_child_list[i].gjc_num = 0;
              temp_child_list[i].zzc_num = 0;
              for (let j = 0; j < childRes.length; j++) {
                if (childRes[j].WarehouseCode == "001") {
                  temp_child_list[i].bs_num = childRes[j].AvailableQuantity;
                }
                if (childRes[j].WarehouseCode == "004") {
                  temp_child_list[i].gjc_num = childRes[j].AvailableQuantity;
                }
                if (childRes[j].WarehouseCode == "20") {
                  temp_child_list[i].zzc_num = childRes[j].AvailableQuantity;
                }
              }
            })
            .catch((error) => {
              common.showErrorMessage(this, error.response.data.result);
            });
        }
        for (let x = 0; x < temp_child_list.length; x++) {
          temp_child_list[x].model_index = x;
        }
        this.order_child_list = temp_child_list; //子订单页面
        this.list = temp_child_list;
      } catch (error) {
        common.showErrorMessage(this, "加载错误," + error);
      } finally {
        this.hideLoading();
      }
    },
    // 详情
    orderInfo(id) {
      this.drawer = true;
      this.order_child_list = [];
      this.chai_child_list = [];
      this.order_child_warehouse_list = [];
      this.getOrderChildList(id);
    },
    // 编辑
    editOrderInfo(id) {
      // if (this.checkAttribute("编辑") == false) {
      //   return;
      // }
      this.dialog_edit = true;
    },
    //保存修改信息
    async submitDialog(edit_data, id) {
      await this.$refs[edit_data].validate((valid) => {
        if (valid) {
          if (
            this.nameInput() == true ||
            this.phoneInput() == true ||
            this.provinceInput() == true ||
            this.cityInput() == true ||
            this.areaInput() == true ||
            this.addressInput() == true
          ) {
            this.edit_data.change_info = 1;
          }
          shopFunction
            .editDetail(id, this.edit_data)
            .then((res) => {
              this.dialog_edit = false;
              this.$confirm("提交成功！", "提示", {
                showCancelButton: false,
                confirmButtonText: "确定",
                type: "success",
                center: true,
              });
              //刷新当前页的信息
              this.requestPage(this.useCurrentPage);
            })
            .catch((error) => {
              common.showErrorMessage(this, error.response.data.result);
            });
        } else {
          this.$confirm("提交失败！", "提示", {
            showCancelButton: false,
            confirmButtonText: "确定",
            type: "error",
            center: true,
          });
          return false;
        }
      });
    },
    //合单-选中订单信息
    handleSelectionChange(change_list, change_row) {
      // 判断选中的数组是否符合合单条件
      let selectRow = change_list.map(
        (item) =>
          item.receiver_name +
          item.receiver_mobile +
          item.receiver_province +
          item.receiver_city +
          item.receiver_area +
          item.receiver_address
      );
      let unique = [...new Set(selectRow)].length;
      if (unique === 1 && change_list.length > 1) {
        this.return_data = true;
        this.H_data_list = change_list;
      } else {
        this.return_data = false;
        this.H_data_list = "";
      }
    },
    //合单操作
    async submitmergeOrder() {
      if (this.return_data === true) {
        //获取新合单订单号
        var mergeOrderNumber = await this.chaiNewOrderCode();
        // console.log("合单新订单号:", mergeOrderNumber);
        this.$confirm("确认合并订单吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "success",
          center: true,
        })
          .then(() => {
            //合单提供给后端的数据是所有合单的主订单号
            //这个请求需要等待后端的合单接口写好后来加入

            //合单思路:
            //合单条件
            //1. 收货人,手机,地址一致
            //2. 待发货状态(判断条件)

            //1.订单头处理:
            // 订单头只需要用第一个来修改型号即可使用,删除id 和 修改order_no 和order_number

            //2.合单型号,数量,金额处理:
            //①.应付和实付如何合并?
            // 合单不用管应付和实付,实付相加,应付根据型号的单价正常计算即可,等到推Model单的时候重新计算分配实付金额到每一个商品上即可
            var mergeOrders = this.H_data_list;
            // console.log(mergeOrders);
            let allPayment = 0;
            let allModel = [];
            let pendingOrderNumber = [];
            for (let index = 0; index < mergeOrders.length; index++) {
              //实付金额叠加
              allPayment += parseInt(mergeOrders[index].payment);
              //所有待合订单号
              pendingOrderNumber.push({
                id: mergeOrders[index].id,
                order_no: mergeOrders[index].order_no,
              });
              //所有待合订单型号合并到新数组中
              for (
                let indexModel = 0;
                indexModel < mergeOrders[index].order_child_list.length;
                indexModel++
              ) {
                allModel.push(mergeOrders[index].order_child_list[indexModel]);
              }
            }
            allPayment = parseFloat(allPayment).toFixed(2).toString();
            //②.同一个型号合并,需要销售价格相同，型号编码相同，且型号编码不能为空，所有型号不同的也添加到新数组中
            var newOrderList = allModel.reduce((accumulator, currentValue) => {
              var foundValue = accumulator.find((item) => {
                if (item.goods_no != "") {
                  return (
                    item.goods_no === currentValue.goods_no &&
                    item.current_price === currentValue.current_price
                  );
                }
              });
              if (foundValue) {
                foundValue.num += currentValue.num;
                foundValue.order_number = mergeOrderNumber;
                delete foundValue.id;
                delete foundValue.order_list;
              } else {
                currentValue.order_number = mergeOrderNumber;
                delete currentValue.id;
                delete currentValue.order_list;
                accumulator.push(currentValue);
              }
              return accumulator;
            }, []);
            // console.log(newOrderList);
            //新订单信息修改（订单头 + 订单内容）
            //将选中的第一个订单作为订单表头
            var newOrder = JSON.parse(JSON.stringify(mergeOrders[0]));
            // 重新计算型号总金额
            var newOrderTotalFee = newOrderList.reduce(
              (accumulator, currentValue) => {
                return (
                  accumulator +
                  parseInt(currentValue.current_price) * currentValue.num
                );
              },
              0
            );
            newOrder.order_no = mergeOrderNumber;
            newOrder.order_child_list = newOrderList;
            newOrder.total_fee = parseFloat(newOrderTotalFee)
              .toFixed(2)
              .toString();
            newOrder.payment = allPayment;
            delete newOrder.id;
            //附加信息添加,有几个待合订单,那么就需要构造几条附加信息
            let originOrderNumber = pendingOrderNumber
              .map((item) => item.order_no)
              .join(",");
            // console.log(originOrderNumber);
            var additional_info = mergeOrders.map((item) =>
              this.recordAdditionalInfo(
                item.id,
                1,
                "合单,原单号:" +
                  originOrderNumber +
                  ",子单号:" +
                  mergeOrderNumber +
                  "\n"
              )
            );
            newOrder["additional_info"] = [];
            newOrder["additional_info"] = additional_info;
            // console.log(newOrder);
            //3.修改原订单信息(多个原订单失效) 多个订单修改并发请求
            // 得到要合单的所有旧订单号
            // console.log(pendingOrderNumber);
            //4.创建合单后的新订单
            // 修改订单并发
            var changeMergeInfo = {
              is_take_effect: {
                choices_id: 2,
                choices_name: "合单失效",
              },
            };
            var requests = pendingOrderNumber.map((item) =>
              shopFunction
                .changeOrderInfo(item.id, changeMergeInfo)
                .then((res) => res)
                .catch((error) =>
                  common.showErrorMessage(this, error.response.data.result)
                )
            );
            // console.log(requests);
            // 创建订单请求
            shopFunction
              .createOrder(newOrder)
              .then((res) => {
                // console.log("这是合单后反馈信息", res);
                if (res.status === 201) {
                  this.$notify({
                    title: "成功",
                    message: "合单成功,请到订单面查看!",
                    type: "success",
                  });
                  //刷新当前页的信息
                  this.requestPage(this.useCurrentPage);
                  // 释放合单变量
                  this.H_data_list = "";
                } else {
                  this.$notify.error({
                    title: "失败",
                    message: "合单失败" + res.data.result,
                  });
                }
              })
              .catch((error) =>
                common.showErrorMessage(this, error.response.data.result)
              );
          })
          .catch(() => {
            this.$notify.error({
              title: "取消",
              message: "取消合单!",
            });
          });
      } else {
        this.$confirm(
          "请检查姓名，电话号码，地址是否一致后进行合单！",
          "提示",
          {
            showCancelButton: false,
            confirmButtonText: "确定",
            type: "error",
            center: true,
          }
        );
      }
    },
    // 拆单-获取新订单编码
    async chaiNewOrderCode() {
      return await shopFunction
        .newOrderCode()
        .then((res) => {
          return res.data.new_order_code;
        })
        .catch((error) => {
          common.showErrorMessage(this, error.response.data.result);
        });
    },
    // 拆单
    async chaiButton() {
      // 拆单条件:
      // 1.1个商品多个数量
      // 2.多个商品多个数量
      // 非拆单条件:
      // 1.1个商品且只有1个数量
      this.showLoading();
      let model_num = this.order_child_list.length;
      if (model_num === 1) {
        // console.log(this.order_child_list[0].num);
        // console.log(typeof this.order_child_list[0].num);
        if (this.order_child_list[0].num === 1) {
          this.$notify.error({
            title: "错误",
            message: "商品和数量都为1,不符合拆单条件!",
          });
          this.hideLoading();
          return;
        }
      }
      // 这里在开发调试,调试好在打开注释
      // if (this.checkAttribute("拆单") == false) {
      //   this.hideLoading();
      //   return;
      // }

      // 获取拆单后的新订单号个数
      let order_code_num = 2;
      this.chai_order_code = [];
      for (let index = 0; index < order_code_num; index++) {
        try {
          const res = await this.chaiNewOrderCode();
          this.chai_order_code.push(res);
        } catch (error) {
          common.showErrorMessage(this, error);
        }
      }
      this.new_order_code = this.chai_order_code[1];
      this.dialogChaiTableVisible = true;
      this.hideLoading();
    },
    // 拆出操作
    handleChange(old_index) {
      //参数: old_index 订单型号里的索引值
      if (this.chai_child_list.length === 0) {
        let deepCopy = JSON.parse(
          JSON.stringify(this.order_child_list[old_index])
        );
        deepCopy.num = 1;
        this.old_order_code = deepCopy.order_number;
        deepCopy.order_number = this.new_order_code;
        this.chai_child_list.push(deepCopy);
      } else {
        let chai_list = this.chai_child_list.length;
        for (let index = 0; index < chai_list; index++) {
          if (
            this.chai_child_list[index].goods_no ===
            this.order_child_list[old_index].goods_no
          ) {
            // 如果 在新拆单变量找到已存在的型号,那么就给这个型号数量+1
            this.chai_child_list[index].num += 1;
            //修改原订单型号数量-1
            this.order_child_list[old_index].num -= 1;
            //如果原来的型号数量拆完了,那么就删除原订单内的这个型号
            if (this.order_child_list[old_index].num === 0) {
              this.order_child_list.splice(old_index, 1);
            }
            return;
          } else if (index == chai_list - 1) {
            //如果 在新拆单变量里未找到该型号,那么就给该型号数量+1
            let deepCopy = JSON.parse(
              JSON.stringify(this.order_child_list[old_index])
            );
            deepCopy.num = 1;
            this.old_order_code = deepCopy.order_number;
            deepCopy.order_number = this.new_order_code;
            this.chai_child_list.push(deepCopy);
          }
        }
      }
      //修改原订单型号数量-1
      this.order_child_list[old_index].num -= 1;
      //如果原来的型号数量拆完了,那么就删除原订单内的这个型号
      if (this.order_child_list[old_index].num === 0) {
        this.order_child_list.splice(old_index, 1);
      }
    },
    // 拆回操作
    chaiChange(new_index) {
      //如果拆单数组为空,那么就直接添加型号
      if (this.order_child_list.length === 0) {
        let deepCopy = JSON.parse(
          JSON.stringify(this.chai_child_list[new_index])
        );
        deepCopy.num = 1;
        this.new_order_code = deepCopy.order_number;
        deepCopy.order_number = this.old_order_code;
        this.order_child_list.push(deepCopy);
      } else {
        let old_list = this.order_child_list.length;
        for (let index = 0; index < old_list; index++) {
          if (
            this.order_child_list[index].goods_no ===
            this.chai_child_list[new_index].goods_no
          ) {
            // 如果 在新拆单变量找到已存在的型号,那么就给这个型号数量+1
            this.order_child_list[index].num += 1;
            //如果原来的型号数量拆完了,那么就删除原订单内的这个型号
            if (this.chai_child_list[new_index].num === 0) {
              this.chai_child_list[new_index] = JSON.parse(JSON.stringify(""));
            }
            //修改原订单型号数量-1
            this.chai_child_list[new_index].num -= 1;
            if (this.chai_child_list[new_index].num === 0) {
              this.chai_child_list.splice(new_index, 1);
            }
            return;
          } else if (index == old_list - 1) {
            let deepCopy = JSON.parse(
              JSON.stringify(this.chai_child_list[new_index])
            );
            deepCopy.num = 1;
            this.new_order_code = deepCopy.order_number;
            deepCopy.order_number = this.old_order_code;
            this.order_child_list.push(deepCopy);
          }
        }
      }
      //修改原订单型号数量-1
      this.chai_child_list[new_index].num -= 1;
      if (this.chai_child_list[new_index].num === 0) {
        this.chai_child_list.splice(new_index, 1);
      }
    },
    // 提交拆单信息到后端
    async submitChaiData() {
      //拆单原理:
      //1.原订单可以逐级拆分,一次只能拆出两个订单,需要拆分多个,重复操作拆单即可;
      //2.原订单拆分后,生成两个新的订单,原订单号及原订单将失效;
      //3.如果价格
      //剔除订单列表型号里的非必要元素
      // console.log("原订单信息:", this.order_info);
      // console.log("原订单信息id:", this.order_info.id);
      var order_child = this.removesChildsElement(this.order_child_list);
      var chai_child = this.removesChildsElement(this.chai_child_list);
      var order_total = this.chaiModelTotal(order_child);
      var chai_total = this.chaiModelTotal(chai_child);
      var order_payment = parseInt(this.order_info.payment);
      //*******************这个total_fee 有BUG ,会和积分的值互串********************
      // var order_total_fee = parseInt(this.order_info.total_fee);
      //*******************这个total_fee 有BUG ,会和积分的值互串********************
      // this.total_amount 是前端重新计算的总价格(型号单价 * 数量)
      var order_total_fee = this.total_amount;
      var order_new_payment = 0;
      var chai_new_payment = 0;
      //计算原订单实付金额和总金额是否相等,如果相等,正常的拆单,如果不相等按照顾比例平摊到每一个商品上
      if (order_payment === order_total_fee) {
        order_new_payment = order_total;
        chai_new_payment = chai_total;
      } else {
        //公式formula
        // 平摊折扣公式:
        // 折扣比率=(应付金额 - 实付金额)/应付金额
        // 平摊实付金额 = 应付单品单价*(1-折扣比率)
        let diff = (order_total_fee - order_payment) / order_total_fee;
        let proprotion = 1 - diff;
        //优惠后,拆单的原订单实付价格
        order_new_payment = Number((order_total * proprotion).toFixed(2));
        //优惠后,拆出新订单的实付价格
        chai_new_payment = Number((chai_total * proprotion).toFixed(2));
      }
      //得到修改后的原表头
      var current_order_info = JSON.parse(JSON.stringify(this.order_info));
      var current_chai_info = JSON.parse(JSON.stringify(this.order_info));
      //构造拆单后的原订单的新订单表头
      current_order_info.payment = order_new_payment;
      current_order_info.total_fee = order_total;
      current_order_info.order_no = this.chai_order_code[0];
      delete current_order_info.id;
      order_child = this.replaceChildsElment(
        order_child,
        "order_number",
        this.chai_order_code[0]
      );
      // 原订单拆后剩余的型号(新订单1)
      current_order_info["order_child_list"] = order_child;
      //附加信息
      current_order_info["additional_info"] = [];
      current_order_info["additional_info"].push(
        this.recordAdditionalInfo(
          this.order_info.id,
          0,
          "拆单,原单号:" +
            this.order_info.order_no +
            ",子单号:" +
            this.chai_order_code +
            "\n"
        )
      );
      // console.log(current_order_info);
      //构造拆单后的新订单表头
      // 拆出来的新订单信息
      current_chai_info.payment = chai_new_payment;
      current_chai_info.total_fee = chai_total;
      current_chai_info.order_no = this.chai_order_code[1];
      delete current_chai_info.id;
      current_chai_info["order_child_list"] = chai_child;

      //附加信息
      current_chai_info["additional_info"] = [];
      current_chai_info["additional_info"].push(
        this.recordAdditionalInfo(
          this.order_info.id,
          0,
          "拆单,原单号:" +
            this.order_info.order_no +
            ",子单号:" +
            this.chai_order_code +
            "\n"
        )
      );
      // console.log(current_chai_info);
      //请求 修改原订单状态:拆单失效
      // 修改内容:
      //1.得到原订单的id,修改拆单失效
      //修改this.edit_data 里的信息修改
      var status_num = 0;
      await shopFunction
        .changeOrderInfo(this.order_info.id, {
          is_take_effect: {
            choices_id: 1,
            choices_name: "拆单失效",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            status_num += 1;
          } else {
            this.$notify.error({
              title: "错误",
              message: "原订单信息修改失败!",
            });
          }
        })
        .catch((error) => {
          common.showErrorMessage(this, error.response.data.result);
        });
      //请求 创建2个新拆订单信息
      await shopFunction
        .createOrder(current_order_info)
        .then((res) => {
          if (res.status === 201) {
            status_num += 1;
          } else {
            this.$notify.error({
              title: "错误",
              message: "原订单拆单创建失败!",
            });
          }
        })
        .catch((error) => {
          common.showErrorMessage(this, error.response.data.result);
        });
      await shopFunction
        .createOrder(current_chai_info)
        .then((res) => {
          if (res.status === 201) {
            status_num += 1;
          } else {
            this.$notify.error({
              title: "错误",
              message: "新拆订单创建失败!",
            });
          }
        })
        .catch((error) => {
          common.showErrorMessage(this, error.response.data.result);
        });
      //显示拆单成功提示通知
      if (status_num === 3) {
        this.$notify({
          title: "成功",
          message: "拆单成功!请到订单面查看!",
          type: "success",
        });
        //隐藏拆单弹窗
        this.dialogChaiTableVisible = false;
        //隐藏详情弹窗
        this.drawer = false;
        //刷新当前页的信息
        await this.requestPage(this.useCurrentPage);
      }
    },
    //拆合后的订单附加记录
    recordAdditionalInfo(orginalId, operation_attribute, remark) {
      return {
        original_order: orginalId,
        operation_attribute: operation_attribute,
        operation_date: common.formattedDateTime(),
        notes: remark,
      };
    },
    //撤销拆合订单
    revokeSplitOrder(cid) {
      // 撤销功能,拆单和合单共同使用
      // 拆单撤销思路>>>原订单查询子订单ID,子订单判断是否发货,如果未发货,那么就可以撤回,删除新拆的所有订单,修改原订单状态
      // 合单撤销思路>>>原订单查询子订单ID,子订单删除,修改原订单状态
      var content = "确认撤回拆单操作吗?";
      let uid = cid.split(",");
      if (uid[0] === "Y") {
        content = "确认撤回 拆单 操作吗?";
      } else {
        content = "确认撤回 合单 操作吗?";
      }
      this.$confirm(content, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        center: true,
      })
        .then(() => {
          shopFunction
            .deleteOrder({ id: cid })
            .then((res) => {
              // console.log(res);
              if (res.status === 204) {
                this.$notify({
                  title: "成功",
                  message: "撤回成功!",
                  type: "success",
                });
                //刷新当前页的信息
                this.requestPage(this.useCurrentPage);
              } else {
                this.$notify.error({
                  title: "错误",
                  message: res.data.result,
                  duration: 0,
                });
              }
            })
            .catch((error) => {
              common.showErrorMessage(this, error.response.data.result);
            });
        })
        .catch(() =>
          this.$notify.error({
            title: "取消",
            message: "取消撤回!",
          })
        );
    },
    // 检查推单和编辑属性
    checkAttribute(content) {
      if (this.order_info.status.choices_name !== "等待卖家发货") {
        common.showErrorMessage(this, `该订单没有待发货属性,不能被${content}!`);
        return false;
      }
      if (this.order_info.is_take_effect.choices_name !== "生效") {
        common.showErrorMessage(
          this,
          `该订单已被拆单失效或合单失效,不能被${content}!`
        );
        return false;
      }
    },
    // 商城订单推单到T+
    async pushingMallOrder() {
      //检测仓库有没有选
      //检测地址省市区是否齐全
      // if (this.verifyAddress() == false) {
      //   return;
      // }
      // if (this.checkAttribute("推单") == false) {
      //   return;
      // }
      if (this.order_info.warehouse === null) {
        common.showErrorMessage(this, "[T+]请选择发货仓库!");
        return;
      }
      this.showLoading();
      await shopFunction
        .pushingMallOrder({ id: this.order_info.id })
        .then((res) => {
          if (res.data.errno === "ok") {
            common.showSuccessMessage(this, res.data.result);
            this.hideLoading();
          } else {
            common.showErrorMessage(this, res.data.result);
            this.hideLoading();
          }
        })
        .catch((error) => {
          common.showErrorMessage(this, error.response.data.result);
        });
    },
    // 推速必达订单
    async pushingSpeedexOrder() {
      //检测仓库有没有选
      //检测地址省市区是否齐全
      // if (this.verifyAddress() == false) {
      //   return;
      // }
      // if (this.checkAttribute("推单到速必达") == false) {
      //   return;
      // }
      // if (this.order_info.warehouse === null) {
      //   common.showErrorMessage(this, "[速必达]请选择发货仓库!");
      //   return;
      // }
      this.showLoading();
      await shopFunction
        .pushingSpeedexOrderRequest({ id: this.order_info.id })
        .then((res) => {
          if (res.data.errno === "ok") {
            common.showSuccessMessage(this, "速必达推单成功!");
          } else {
            common.showErrorMessage(this, res.data.result);
          }
          this.hideLoading();
        })
        .catch((error) => {
          common.showErrorMessage(this, error.response.data.result);
          this.hideLoading();
        });
    },
    //生成消息模板
    async generateMessageTemplate() {
      //出库单号,备注,速必达模式
      //判断仓库是否是公家村仓库,如果是,那么才可以使用这个功能
      if (this.order_info.warehouse !== 2) {
        common.showErrorMessage(this, "[模版消息]仓库属性不是公家村仓库!");
        return;
      }
      this.showLoading();
      await shopFunction
        .generateSpeedexMessagesId({ id: this.order_info.id })
        .then((res) => {
          if (res.data.errno == "ok") {
            this.messageTemplate = res.data.result[0];
            this.messageDrawer = true; //弹出提示的消息模版
          } else {
            common.showErrorMessage(this, res.data.result[0]);
          }
        })
        .catch((error) => {
          common.showErrorMessage(this, error.response.data.result);
        });
      this.hideLoading(); //隐藏遮罩层
    },
    //一键推单
    async oneClickPushOrder() {
      try {
        //这里推单成功后,要返回T+出库单,给后面生成消息模板使用
        //检测地址省市区是否齐全
        if (this.verifyAddress() == false) {
          return;
        }
        await this.pushingMallOrder();
        await this.pushingSpeedexOrder();
        await this.generateMessageTemplate();
        //刷新当前页的信息
        await this.requestPage(this.useCurrentPage);
      } catch (error) {
        common.showErrorMessage(this, error);
      }
    },
    //推单前校验省,市,区是否都齐全,不齐全的话提示补全
    verifyAddress() {
      let name = "";
      if (this.order_info.receiver_province === "") {
        name = "<省份>";
      } else if (this.order_info.receiver_city === "") {
        name = "<市>";
      } else if (this.order_info.receiver_area === "") {
        name = "<区/县>";
      } else if (this.order_info.receiver_address === "") {
        name = "<详细地址>";
      }
      if (name !== "") {
        common.showErrorMessage(this, `地址错误,缺少${name}名称,请修改地址!`);
        return false;
      } else {
        return true;
      }
    },
    // 发货
    sendGoods(cid, status, is_take_effect) {
      this.$confirm("确定发货吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        center: true,
      })
        .then(() => {
          if (status !== "等待卖家发货") {
            common.showErrorMessage(this, `该订单没有待发货属性,不能被发货!`);
            return false;
          }
          if (is_take_effect !== "生效") {
            common.showErrorMessage(
              this,
              `该订单已被拆单失效或合单失效,不能被发货!`
            );
            return false;
          }
          shopFunction
            .sendGoods({ id: cid })
            .then((res) => {
              if (res.data.errno === "ok") {
                //发货完成后直接同步订单,无需提示
                this.onLoadSynOrders(0);
                common.showSuccessMessage(this, res.data.result);
              } else {
                common.showErrorMessage(this, res.data.result);
              }
            })
            .catch((error) => {
              common.showErrorMessage(this, error.response.data.result);
            });
        })
        .catch(() =>
          this.$notify.error({
            title: "取消",
            message: "取消发货!",
          })
        );
    },

    // 叠加所有型号数量
    addNum(modelList) {
      return modelList.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.num;
      }, 0);
    },
    //剔除订单列表型号里的非必要元素
    removesChildsElement(temp) {
      let del_arr = ["id", "order_list", "bs_num", "gjc_num", "zzc_num"];
      let new_temp = [];
      for (let index = 0; index < temp.length; index++) {
        for (let index1 = 0; index1 < del_arr.length; index1++) {
          delete temp[index][del_arr[index1]];
        }
        new_temp.push(temp[index]);
      }
      return new_temp;
    },
    //替换订单型号列表里的元素内容
    replaceChildsElment(temp, old_element, content) {
      for (let index = 0; index < temp.length; index++) {
        temp[index][old_element] = content;
      }
      return temp;
    },
    //拆单型号总金额计算
    chaiModelTotal(temp) {
      var total = 0;
      for (let index = 0; index < temp.length; index++) {
        total += temp[index].current_price * temp[index].num;
      }
      return total;
    },
    // 得到拆单合单表头
    getNewTitle(temp, new_order_code) {
      let del_arr = ["id"];
      //剔除不要的元素
      for (let index = 0; index < del_arr.length; index++) {
        delete temp[del_arr[index]];
      }
      //将原订单号修改为新订单号
      temp.order_no = new_order_code;
      // total_fee,payment
      // 实付金额和总金额因为优惠产生差异:
      //解决办法:1.按照总金额推单,产生的优惠差异推单的时候从新生成一单优惠减免的单子

      // 计算当前拆单后的订单总金额
      return temp;
    },
    // 不询问直接同步订单
    onLoadSynOrders(num) {
      this.showLoading();
      shopFunction
        .synShopOrder(num)
        .then((res) => {
          if (res.data.result == "ok") {
            common.showSuccessMessage(this, "微分销订单同步成功!");
            //同步成功后,刷新当前页
            this.requestPage(this.useCurrentPage);
          } else {
            common.showErrorMessage(this, res.data.result);
          }
          this.hideLoading();
        })
        .catch((error) => {
          common.showErrorMessage(this, error.response.data.result);
        });
    },
    // 同步订单 num:0 同步10天前到至今,num:同步所有订单
    syncOrders(num) {
      this.$confirm("确定同步订单吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.showLoading();
          shopFunction
            .synShopOrder(num)
            .then((res) => {
              if (res.data.result == "ok") {
                this.searchState();
                common.showSuccessMessage(this, "同步订单成功!");
              } else {
                common.showErrorMessage(this, "同步订单失败");
              }
              this.hideLoading();
            })
            .catch((error) => {
              common.showErrorMessage(this, error.response.data.result);
            });
        })
        .catch(() => {
          this.$message({
            type: "warning",
            message: "已取消同步",
          });
        });
    },
    // 显示加载
    showLoading() {
      this.loading = this.$loading({
        lock: true,
        text: "拼命加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    },
    // 隐藏加载
    hideLoading() {
      this.loading.close();
    },
    // 监听以下输入框值是否发生改变 改变了就返回true
    nameInput() {
      return true;
    },
    phoneInput() {
      return true;
    },
    provinceInput() {
      return true;
    },
    cityInput() {
      return true;
    },
    areaInput() {
      return true;
    },
    addressInput() {
      return true;
    },
  },
};
</script>

<style lang="scss">
.required-label {
  background: #ff0606;
}

.content-box {
  padding: 0;

  .dialog-edit {
    .el-dialog {
      .el-dialog__header {
        padding-top: 5vh;
      }

      .el-descriptions {
        .my-edit-label {
          width: 10vw;
          text-align: center;
        }

        .my-edit-content {
          width: 40vw;

          .el-textarea {
            .el-textarea__inner {
              height: 0vh;
            }
          }
        }

        .my-label {
          text-align: center;
          width: 12vw;
        }

        .my-content {
          width: 13vw;
        }
      }
    }
  }

  .el-table .title,
  .cell {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
