<template>
  <div>
    <h4>宛松助手订单重推(未写入到T+的订单)</h4>
    <el-form :rules="rules" :model="ruleForm" ref="ruleForm">
      <el-form-item label="订单号" prop="order_number" label-width="5vw">
        <el-input
          v-model="ruleForm.order_number"
          placeholder="请输入订单号"
          style="width: 20vw"
        ></el-input>
        <el-button
          style="margin-left: 20px"
          type="primary"
          plain
          @click="submitForm"
          >写入T+</el-button
        >
      </el-form-item>
      <el-form-item> </el-form-item>
    </el-form>
  </div>
</template>

<script>
import * as shopFunction from "@/api/shop";
import * as common from "@/utils/common.js";
export default {
  data() {
    return {
      ruleForm: {
        order_number: "",
      },

      rules: {
        order_number: [
          {
            required: true,
            message: "请输入订单号",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    //显示提交
    showLoading() {
      this.loading = this.$loading({
        lock: true,
        text: "提交中！",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    },
    //隐藏提交
    hideLoading() {
      this.loading.close();
    },
    //提交按钮
    async submitForm() {
      this.showLoading();
      await shopFunction
        .rewriteOrder(this.ruleForm)
        .then((res) => {
          if (res.data.errno === "fail") {
            this.$confirm("提交失败！", "提示", {
              showCancelButton: false,
              confirmButtonText: "确定",
              type: "warning",
              center: true,
            });
          } else {
            this.$confirm("提交成功！", "提示", {
              showCancelButton: false,
              confirmButtonText: "确定",
              type: "success",
              center: true,
            });
          }
        })
        .catch((error) => {
          common.showErrorMessage(this, error.response.data.result);
        });
      this.hideLoading();
    },
  },
};
</script>

<style>
</style>