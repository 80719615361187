// 全局基础配置
import axios from "axios"
// 配置中心
import webConfig from "@/global.config.js"
import router from "./router"
// import { Base64 } from "js-base64"
let request = axios.create({
    baseURL: webConfig.baseUrl,
    timeout: 15 * 60 * 1000,
    responseType: "json",
    // hearders: {
    //     "test": "123"
    // }
})
request.interceptors.request.use((config) => {
    //token ,密钥配置
    //白名单里的请求不需要token
    let whiteList = webConfig.whiteListApi
    let url = config.url
    // console.log(url)
    let token = sessionStorage.getItem("etoken")
    if (whiteList.indexOf(url) === -1) {
        config.headers.etoken = token
    }
    // 密钥 - secret + 特殊算法
    // _scret = Base64.encode(webConfig.secretId + new Date().toString())
    // config.headers.scret = _scret
    return config

}, error => {
    return Promise.reject(new Error(error))
}
)
request.interceptors.response.use((res) => {
    //响应统一处理
    const status = res.status || 200
    const message = res.data.result || "未知错误!"

    if (status === 200) {
        if (res.data.result == "token已失效") {
            router.push({ path: "/" })
            return Promise.reject(new Error("Token已过期,请重新登录!"))
        }
    }
    if (status === 401) {
        router.push({ path: "/" })
        return Promise.reject(new Error("你没有权限"))
    }
    if (status > 204) {
        return Promise.reject(new Error("错误码" + status + "   " + message))
    }
    return res
}
    // , error => {
    //     //真实项目中使用组件库中的消息提示 $waring 或 $message
    //     return Promise.reject(new Error(error.response.data))
    // }
)
export default request