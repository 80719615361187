import request from "@/axios";
import { initRequest, pdRequest } from "./request";
//1.登录
export const userLogin = (currentData) => {
    return pdRequest({
        url: "/api/ws/login/",
        method: "post",
        data: currentData
    })
}
//2.注册账号
export const registerAccount = (currentData) => {
    return pdRequest({
        url: "/api/staff/register/",
        method: "post",
        data: currentData
    })
}
//3.重置密码
export const restPassword = (currentData) => {
    return pdRequest({
        url: "/api/reset/pwd/",
        method: "post",
        data: currentData
    })
}
//4.账号解锁
export const unlockAccount = (currentData) => {
    return pdRequest({
        url: "/api/permissions/group/",
        method: "post",
        data: currentData
    })
}
//5.搜索用户
export const searchAccount = (params) => {
    return pdRequest({
        url: "/api/search/account/list/",
        method: "get",
        params: {
            ...params
        }
    })
}
//6.编辑用户信息
export const editUserAccount = (params) => {
    return pdRequest({
        url: "/api/search/account/list/",
        method: "get",
        params: {
            ...params
        }
    })
}
//7.编辑用户确定修改
export const confirmChangeUserInfo = (currentData) => {
    return pdRequest({
        url: "/api/permissions/group/",
        method: "post",
        data: currentData
    })
}
//8.报乐助手异常列表
export const exceptLeAssistanttList = () => {
    return pdRequest({
        url: "/api/query/except/",
        method: "post",
    })
}
//9.修改报乐助手状态
export const changeLeAssistantStatus = (currentData) => {
    return pdRequest({
        url: "/api/change/state/",
        method: "post",
        data: currentData
    })
}
//10.获取部门及岗位列表
export const getAllDepartmentList = () => {
    return pdRequest({
        url: "/api/department/list/",
        method: "get"
    })
}
//11.获取权限分配列表
export const getPermissionList = () => {
    return pdRequest({
        url: "/api/permission/group/name/",
        method: "get"
    })
}
//12.获取账号列表
export const getAccountList = (currentData) => {
    return pdRequest({
        url: "/api/account/list/",
        method: "post",
        data: currentData
    })
}
//13.生成速必达消息模板模式,备注,出库单号
export const generateSpeedexMessages = (currentData) => {
    return pdRequest({
        url: "/speedex/group/message/",
        method: "post",
        data: currentData
    })
}
//13.生成速必达消息模板ID接口
export const generateSpeedexMessagesId = (params) => {
    return pdRequest({
        url: "/TuiDanapi/generate_template_message/",
        method: "get",
        params: { ...params }
    })
}
//generate_template_message
//14.提交生成速必达订单
export const generateSpeedexOrders = (currentData) => {
    return pdRequest({
        url: "/speedex/simulation/",
        method: "post",
        data: currentData
    })
}


//15.获取订单列表
export const getShopOrderList = (params) => {
    return initRequest({
        url: "/TuiDanapi/orders/",
        method: "get",
        params: {
            ...params
        }
    })
}
//16.获取仓库列表
export const getShopWarehouseList = (params) => {
    return initRequest({
        url: "/TuiDanapi/warehouses/",
        method: "get",
        params: {
            ...params
        }
    })
}

// 下载宛松助手指定日期范围订单
export const downLoadAssistantOrders = (currentData) => {
    return pdRequest({
        url: "/api/export_order/tables/",
        method: "post",
        data: currentData,
        responseType: "arraybuffer"
    })
}

// 删除订单
export const deleteOrder = (params) => {
    return initRequest({
        url: "/TuiDanapi/split_withdraw/",
        method: "get",
        params: {
            ...params
        }
    })
}
// 发货状态修改
export const sendGoods = (params) => {
    return initRequest({
        url: "/TuiDanapi/send_goods/",
        method: "get",
        params: {
            ...params
        }
    })
}


//18.单个订单信息
export const getAloneOrder = (id) => {
    return initRequest({
        url: "/TuiDanapi/orders/" + id + "/",
        method: "get",
    })
}
// 创建订单
export const createOrder = (currentData) => {
    return initRequest({
        url: "/TuiDanapi/orders/",
        method: "post",
        data: currentData
    })
}
// 修改订单信息
export const changeOrderInfo = (id, currentData) => {
    return initRequest({
        url: "/TuiDanapi/orders/" + id + "/",
        method: "put",
        data: currentData
    })
}
//19.单编码查询畅捷通库存情况
export const getCurrentInventory = (params) => {
    return initRequest({
        url: "/TuiDanapi/chanjet/available/quantity/",
        method: "get",
        params: {
            ...params
        }
    })
}
//17.订单列表分页
export const getPaginationOrder = (params) => {
    return initRequest({
        url: "/TuiDanapi/orders/",
        method: "get",
        params: {
            ...params
        }
    })
}
//21.同步订单
export const synShopOrder = (num) => {
    return pdRequest({
        url: "TuiDanapi/orders/" + num + "/syn/",
        method: "get"
    })
}
//22.修改订单详情
export const editDetail = (id, currentData) => {
    return initRequest({
        url: "TuiDanapi/orders/" + id + "/",
        method: "put",
        data: currentData
    })
}
// 商城订单推单到T+
export const pushingMallOrder = (params) => {
    return initRequest({
        url: "/TuiDanapi/pushing_mall_orders/",
        method: "get",
        params: { ...params }
    })
}
// 商城订单推单到速必达物流
export const pushingSpeedexOrderRequest = (params) => {
    return initRequest({
        url: "/TuiDanapi/pushing_speedex_order/",
        method: "get",
        params: { ...params }
    })
}


//23.速必达配送模式查询
export const speedexModel = () => {
    return initRequest({
        url: "speedex/models/",
        method: "get",
    })
}
//24.新订单号
export const newOrderCode = () => {
    return initRequest({
        url: "TuiDanapi/new_code/",
        method: "get",
    })
}

//27.宛松助手重推订单
export const rewriteOrder = (currentData) => {
    return initRequest(
        {
            url: "/api/rewrite/order/",
            method: "post",
            data: currentData
        }
    )
}

//三方编码列表
export const tripartiteCoding = (params) => {
    return initRequest(
        {
            url: "/TuiDanapi/codes/",
            method: "get",
            params: { ...params }
        }
    )
}

//修改三方编码
export const changeTripartiteCoding = (id, currentData) => {
    return initRequest(
        {
            url: "/TuiDanapi/codes/" + id + "/",
            method: "put",
            data: currentData
        }
    )
}
//新增三方编码
export const addTripartiteCoding = (currentData) => {
    return initRequest(
        {
            url: "/TuiDanapi/codes/",
            method: "post",
            data: currentData
        }
    )
}
//删除三方编码
export const deleteTripartiteCoding = (id) => {
    return initRequest(
        {
            url: "/TuiDanapi/codes/" + id + "/",
            method: "delete"
        }
    )
}
//搜索三方编码
export const searchTripartiteCoding = (params) => {
    return initRequest(
        {
            url: "/TuiDanapi/codes/?",
            method: "get",
            params: { ...params }
        }
    )
}