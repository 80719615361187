<template>
  <div class="box">
    <h4>上传会销报乐助手文件</h4>
    <el-button type="primary" plain @click="upload">上传</el-button>
    <el-upload
      show-file-list="false"
      clearFiles
      :on-success="uploadSuccess"
      :on-error="uploadError"
      :on-progress="uploadProgress"
      :on-remove="uploadRemove"
      class="upload"
      ref="upload"
      drag
      v-bind:action="upload_url"
      multiple
      :auto-upload="false"
    >
      <i class="el-icon-upload"></i>
      <div>将文件拖到此处，或<em>点击上传</em></div>
    </el-upload>
    <div class="hint" slot="tip">
      注意：
      <br />
      <ol>
        <li>Excel文件上传文件格式: .xls(不支持.xlsx格式)</li>
        <li>记事本文件上传格式: .txt</li>
      </ol>
    </div>
  </div>
</template>

<script>
import webConfig from "@/global.config.js";
export default {
  data() {
    return {
      upload_url: webConfig.baseUrl + "/api/upload/",
      //提交遮罩层
      loading: "",
    };
  },
  methods: {
    //删除
    uploadRemove() {},
    //显示提交
    showLoading() {
      this.loading = this.$loading({
        lock: true,
        text: "上传中！",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    },
    //隐藏提交
    hideLoading() {
      this.loading.close();
    },
    //文件上传时
    uploadProgress() {
      this.showLoading();
    },
    //文件上传失败
    uploadError(file) {
      this.hideLoading();
      this.$confirm("上传失败！", "提示", {
        showCancelButton: false,
        confirmButtonText: "确定",
        type: "error",
        center: true,
      });
    },
    //文件上传成功
    uploadSuccess() {
      this.hideLoading();
      this.$confirm("上传成功！", "提示", {
        showCancelButton: false,
        confirmButtonText: "确定",
        type: "success",
        center: true,
      });
    },
    //文件上传点击
    upload() {
      this.$refs.upload.submit();
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 20vw;
  .hint {
    font-size: small;
  }
  .upload {
    margin: 3vh 0 2vh 0;
  }
}
</style>