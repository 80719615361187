<template>
  <div class="content">
    <el-row>
      <el-col :span="12">
        <!-- 头部按钮 -->
        <div class="content_header">
          <!-- 提交按钮 -->
          <el-button
            style="margin-left: 10px"
            type="primary"
            @click="submitForm('billing_info')"
            >提交</el-button
          >
          <!-- 生成消息模版按钮 -->
          <el-button type="success" plain @click="crestMessage"
            >生成消息模板</el-button
          >
          <!-- 生成Excel表格按钮 -->
          <a v-bind:href="download_url">
            <el-button style="margin-left: 10px" type="success" plain
              >生成Excel表格</el-button
            >
          </a>
        </div>
        <!-- 出库单号 -->
        <el-form label-width="80px">
          <div v-for="(billing, index) in billing_info" :key="index">
            <el-form-item label="配送方式">
              <el-radio-group class="radio-group" v-model="billing.mode_name">
                <el-radio label="宅配模式"></el-radio>
                <el-radio label="自提模式"></el-radio>
              </el-radio-group>
              <el-button
                style="margin-left: 10px"
                type="primary"
                plain
                @click="addDomain"
                >新增</el-button
              >
              <el-button
                type="danger"
                plain
                @click.prevent="removeDomain(billing)"
                >删除</el-button
              >
            </el-form-item>
            <el-form-item label="出库单号">
              <el-input
                placeholder="请输入出库单号"
                value=""
                style="width: 20vw"
                v-model="billing.order_number"
              ></el-input>
            </el-form-item>
            <el-form-item label="备注">
              <el-input
                placeholder="请输入出库备注"
                value=""
                style="width: 20vw"
                type="textarea"
                v-model="billing.speedex_remark"
              ></el-input>
            </el-form-item>
          </div>
        </el-form>

        <!-- 抽屉弹出层（生成消息模板） -->
        <div>
          <el-drawer
            title="消息模版"
            :visible.sync="dialogVisible"
            direction="rtl"
            :before-close="handleClose"
          >
            <div style="margin: 0 1.5rem">
              <span>------------------------------------------</span>
              <div v-for="(item, index) in message_info" :key="index">
                <span>订单类型：{{ item.订单类型 }}</span>
                <br />
                <span>客户委托号：{{ item.客户委托号 }}</span>
                <br />
                <span>收货联系人：{{ item.收货联系人 }}</span>
                <br />
                <span>收货联系人手机：{{ item.收货联系人手机 }}</span>
                <br />
                <span>收货地址：{{ item.收货地址 }}</span>
                <br />
                <div v-for="(type, i) in message_info[index].型号" :key="i">
                  型号：
                  <br />
                  <span>商品名称：{{ type.商品名称 }}</span
                  ><span> 数量：{{ type.数量 }}</span>
                  <br />
                  <span>客户商品编码：{{ type.客户商品编码 }}</span>
                  <br />
                </div>
                <span>订单备注：{{ item.订单备注 }}</span>
                <br />
                <span
                  >-----------------------------------------------------</span
                >
                <br />
              </div>
            </div>
          </el-drawer></div
      ></el-col>

      <!-- 速必达编码维护模块

      <el-col :span="6">
        <div class="box">
          <div class="title">速必达编码维护</div>

          <el-button type="primary" plain @click="upload">上传</el-button>
          <el-upload
            style="margin-top: 2vh"
            clearFiles
            :on-success="uploadSuccess"
            :on-error="uploadError"
            :on-progress="uploadProgress"
            :on-remove="uploadRemove"
            ref="upload"
            drag
            v-bind:action="upload_url"
            multiple
            :auto-upload="false"
          >
            <i class="el-icon-upload"></i>
            <div>将文件拖到此处，或<em>点击上传</em></div>
          </el-upload>
          <div slot="tip">
            注意：
            <br />
            <ul>
              <li>Excel文件上传文件格式: .xlsx(不支持.xls格式)</li>
            </ul>
          </div>
          <div slot="tip">
            说明：
            <br />
            <ol>
              <li>
                每次速必达有新编码入库，都需要将新品编码通过EXCEL上传的方式传入到数据库当中，
                否则T+出库单推送到速必达会报错（错误信息：速必达未查到该编码）
              </li>
              <li>
                编辑EXCEL表格第一列是速必达编码（速必达编码可与工厂编码同为一个，也可以不同，具体情况跟第三方仓库沟通编辑速必达编码），第二列是速必达名称，第三列是工厂编码
              </li>
              <li>每次更新编码都需包含之前的旧编码，否则旧编码会被替换</li>
            </ol>
          </div>
        </div>
      </el-col>
       -->
    </el-row>
  </div>
</template>

<script>
import * as shopFunction from "@/api/shop";
import * as common from "@/utils/common.js";
import webConfig from "@/global.config.js";
export default {
  data() {
    return {
      upload_url: webConfig.baseUrl + "/speedex/update/speedex_code/",
      download_url: webConfig.baseUrl + "/speedex/excel/download/",
      dialogVisible: false,
      message_info: [],
      type: [],
      billing_info: [
        {
          mode_name: "",
          order_number: "",
          speedex_remark: "",
        },
      ],
    };
  },
  onLoad() {},
  methods: {
    // 是否确认关闭抽屉
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },

    // 生成信息模版点击事件
    async crestMessage() {
      //清空原来的模板数据
      this.message_info = [];
      let res = common.isTraverseList(this.billing_info);
      if (res.result == false) {
        this.$message({
          type: "error",
          message: "请检查相关信息后重新生成信息模版!",
          duration: 5000,
        });
        return;
      }
      if (res.result == true) {
        this.showLoading(); //显示提交中遮罩层
        shopFunction
          .generateSpeedexMessages(this.billing_info)
          .then((res) => {
            var message = res.data.result;
            console.log(message);
            for (let i = 0; i < message.length; i++) {
              for (let j = 0; j < message[i].length; j++) {
                this.message_info.push(message[i][j]);
                for (let k = 0; k < message[i][j].型号.length; k++) {
                  this.type.push(message[i][j].型号[k]);
                }
              }
            }
            this.dialogVisible = true; //弹出提示的消息模版
          })
          .catch((error) => {
            common.showErrorMessage(this, error.response.data.result);
          });
        this.hideLoading(); //隐藏遮罩层
      }
    },

    // 提交点击事件
    async submitForm() {
      let res = common.isTraverseList(this.billing_info);
      if (res.result == false) {
        this.$message({
          type: "error",
          message: "请检查相关信息后重新生成信息模版!",
          duration: 5000,
        });
      }
      if (res.result == true) {
        try {
          this.showLoading();
          await shopFunction
            .generateSpeedexOrders(this.billing_info)
            .then((response) => {
              if (response.data.errno == "ok") {
                this.$confirm("提交成功！", "提示", {
                  showCancelButton: false,
                  confirmButtonText: "确定",
                  type: "success",
                  center: true,
                });
                this.$message.success("提交成功!");
              } else {
                this.$confirm("提交失败！" + response.data.result, "提示", {
                  showCancelButton: false,
                  confirmButtonText: "确定",
                  type: "error",
                  center: true,
                });
              }
            })
            .catch((error) => {
              common.showErrorMessage(this, error.response.data.result);
            });
        } catch (e) {
          this.hideLoading();
          this.$message.error("提交失败!" + e);
        } finally {
          this.hideLoading();
        }
      }
    },

    //显示提交
    showLoading() {
      this.loading = this.$loading({
        lock: true,
        text: "提交中！",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    },

    //隐藏提交
    hideLoading() {
      this.loading.close();
    },

    // 删除出库单
    removeDomain(item) {
      this.$confirm("此操作将删除该待推单的单号和备注, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        // confirmButtonClass :'confirmButtonClass',
        type: "error",
        // center: true
      })
        .then(() => {
          var index = this.billing_info.indexOf(item);
          if (index !== -1) {
            this.billing_info.splice(index, 1);
          }
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    // 增加出库单
    addDomain() {
      this.billing_info.push({});
    },

    //显示提交
    showLoading() {
      this.loading = this.$loading({
        lock: true,
        text: "上传中！",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    },
    //隐藏提交
    hideLoading() {
      this.loading.close();
    },
    //上传文件
    //删除
    uploadRemove() {},

    //文件上传时
    uploadProgress() {
      this.showLoading();
    },
    //文件上传失败
    uploadError() {
      this.hideLoading();
      this.$confirm("上传失败！", "提示", {
        showCancelButton: false,
        confirmButtonText: "确定",
        type: "error",
        center: true,
      });
    },
    //文件上传成功
    uploadSuccess() {
      this.hideLoading();
      this.$confirm("上传成功！", "提示", {
        showCancelButton: false,
        confirmButtonText: "确定",
        type: "success",
        center: true,
      });
    },
    //文件上传点击
    upload() {
      this.$refs.upload.submit();
    },
  },
};
</script>

<style lang="scss" scoped>
// 最外层盒子
.content {
  // 头部按钮样式

  .content_header {
    margin-bottom: 2rem;
  }
  // 表单样式
  .el-form {
    .el-form-item {
      .radio-group {
        margin: 0 1rem;
      }
    }
  }

  //速必达编码维护
  .box {
    // 标题
    .title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 2vh;
    }
  }
}
</style>