import request from "@/axios";
let preventDuplicationdRequest = (function () {
    let hasRequest = [];
    return async function (config) {
        let url = config.url
        if (hasRequest.indexOf(url) !== -1) {
            return Promise.reject({ message: "请求已提交!" })
        }
        hasRequest.push(url)
        //开始请求,请求成功后返回数据,并从数组里删除这个URL
        const res = await request({
            ...config
        });
        // 删除当前请求成功的ulr地址
        hasRequest = hasRequest.filter((item) => {
            if (item !== url) {
                return item;
            }
        });
        return res;
    }

})()

export {
    preventDuplicationdRequest as pdRequest,
    request as initRequest
}